import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  jurisdictions: [],
  jurisdictionsValueList: [],
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /*-----ADD ENTRY FIELDS BELOW-----*/
    name: '',
    flag_status: '',
  },
  filters: {
    page: '',
    /*-----ADD FILTER FIELDS BELOW-----*/
    name: '',
    flag_status: ''
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.jurisdictions = [];
    state.jurisdictionsValueList = [];
    state.filters = {
      page: '',
      flag_status: '',
      name: '',
    };
  },
  FETCH(state, { list }) {
    state.jurisdictions = list;
  },
  CREATE(state, { data }) {
    state.jurisdictions.data.push(data);
  },
  READ(state, { data }) {
    state.jurisdiction = data;
  },
  UPDATE(state, { data }) {
    const idx = state.jurisdictions.data.map(item => item.id).indexOf(data.id);
    state.jurisdictions.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.jurisdictions.data.map(item => item.id).indexOf(id);
    state.jurisdictions.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.name = data.name;
    state.entry.flag_status = data.flag_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.name = '';
    state.entry.flag_status = 1;
  },
  VALUE_LIST(state, { list }) {
    state.jurisdictionsValueList = list;
  },
  FETCH_BY_PROJECT(state, { list }) {
    state.jurisdictionsValueList = list;
  },
  FETCH_BY_COHORT(state, { list }) {
    state.jurisdictionsValueList = list;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/jurisdictions', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/jurisdictions/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/jurisdictions/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/jurisdictions/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/jurisdictions/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/jurisdictions/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }) {
    try {
      const response = await axios.get('/api/jurisdictions/valuelist/1', { headers: getHeader() });
      commit('VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async byProject({ commit }, data) {
    try {
      const response = await axios.post('/api/jurisdictions/project', data, { headers: getHeader() });
      commit('FETCH_BY_PROJECT', { list: response.data });
    } catch (error) {

    }
  },
  async byCohort({ commit }, data) {
    try {
      const response = await axios.post('/api/jurisdictions/cohort', data, { headers: getHeader() });
      commit('FETCH_BY_COHORT', { list: response.data });
    } catch (error) {

    }
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getJurisdictions: (state, getters) => state.jurisdictions,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
