import { getHeader } from '@/env';

const state = {
  navigation: [],
  navigationAdmin:[],
  nav: '',
};

const mutations = {
  CLEAR_STORE(state) {
    state.navigation = [];
    state.navigationAdmin = [];
    state.nav = '';
  },
  CLEAR_NAVIGTION(state) {
    state.navigation = [];
  },
  FETCH(state, { list }) {
    state.navigation = list;
  },
  FETCH_ADMIN(state, { list }) {
    state.navigationAdmin = list;
  },
  CLEAR_NAVIGATION(state) {
    state.navigation = [];
  },
  CLEAR_NAVIGATION_ADMIN(state) {
    state.navigationAdmin = [];
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },

  clearNavigation: ({ commit }) => {
    commit('CLEAR_NAVIGATION');
  },

  async fetch({ commit } , data) {
    try {
      const response = await axios.post('/api/navigationmain', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  
  async fetchAdmin({ commit }) {
    try {
      const response = await axios.get('/api/navigationadmin', { headers: getHeader() });
      commit('FETCH_ADMIN', { list: response.data });
    } catch (error) {

    }
  },

  clearNavigation: ({ commit }) => {
    commit('CLEAR_NAVIGATION');
  },

  clearNavigationAdmin: ({ commit }) => {
    commit('CLEAR_NAVIGATION_ADMIN');
  },
};

const getters = {
  getNavigation: (state, getters) => state.navigation,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
