import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  faqs: [],
  faqList: [],
  faq: null,
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',
    /*-----ADD ENTRY FIELDS BELOW-----*/
    title: '',
    text: '',
    sort: '',
    flag_type: '',
    flag_status: ''
  },
  filters: {
    page: '',
    /*-----ADD FILTER FIELDS BELOW-----*/
    title: '',
    flag_status: ''
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.faqs = [];
    state.faqList = [];
    state.faq = null;
    state.filters = {
      title: '',
      flag_status: '',
    };
  },
  FETCH(state, { list }) {
    state.faqs = list;
  },
  CREATE(state, { data }) {
    state.faqs.data.push(data);
  },
  READ(state, { data }) {
    state.faq = data;
  },
  UPDATE(state, { data }) {
    const idx = state.faqs.data.map(item => item.id).indexOf(data.id);
    state.faqs.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.faqs.data.map(item => item.id).indexOf(id);
    state.faqs.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.title = data.title;
    state.entry.text = data.text;
    state.entry.sort = data.sort;
    state.entry.flag_type = data.flag_type;
    state.entry.flag_status = data.flag_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.title = '';
    state.entry.text = '';
    state.entry.sort = '';
    state.entry.flag_type = '';
    state.entry.flag_status = 1;
  },
  LIST(state, { list }) {
    state.faqList = list;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/faqs', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/faqs/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/faqs/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/faqs/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/faqs/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/faqs/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/faqs/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async list({ commit }) {
    try {
      const response = await axios.get('/api/faqslist', { headers: getHeader() });
      commit('LIST', { list: response.data });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  }
};

const getters = {
  getFaqs: (state, getters) => state.faqs,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
