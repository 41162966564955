import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  helpTopics: [],
  helpTopic: {},
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',

    title: '',
    route: '',
    text: '',
    flag_type: '',
    flag_status: '',
  },
  help: {
    show: '',
    title: '',
    text: '',
  },
  filters: {
    page: '',

    id: '',
    title: '',
    flag_status: ''
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.helpTopics = [];
    state.helpTopic = null;
    state.filters = {
      id: '',
      title: '',
      flag_status: ''
    };
  },
  FETCH(state, { list }) {
    state.helpTopics = list;
  },
  CREATE(state, { data }) {
    state.helpTopics.data.push(data);
  },
  READ(state, { data }) {
    state.helpTopic = data;
  },
  UPDATE(state, { data }) {
    const idx = state.helpTopics.data.map(item => item.id).indexOf(data.id);
    state.helpTopics.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.helpTopics.data.map(item => item.id).indexOf(id);
    state.helpTopics.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.route = data.route;
    state.entry.text = data.text;
    state.entry.title = data.title;
    state.entry.flag_type = data.flag_type;
    state.entry.flag_status = data.flag_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.route = '';
    state.entry.text = '';
    state.entry.title =  '';
    state.entry.flag_type = '';
    state.entry.flag_status = 1;
  },
  POPULATE_HELP(state, { data }) {
    state.help.text = data.text;
    state.help.title = data.title;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateHelpField(state, field) {
    updateField(state.help, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/helptopics', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/helptopics/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/helptopics/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/helptopics/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/helptopics/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/helptopics/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/helptopics/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async help({ commit }, data) {
    try {
      const response = await axios.post('/api/helptopics/help', data, { headers: getHeader() });
      commit('POPULATE_HELP', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
};

const getters = {
  getHelpTopics: (state, getters) => state.helpTopics,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getHelpField(state) {
    return getField(state.help);
  },
  getEntry: (state, getters) => state.entry,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
