import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const state = {
  outcomes: [],
  outcome: {},
  export: [],
  entry: {
    show: false,
    valid: "",
    mode: "",
    view: "",
    id: "",

    tab: "",

    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_user: "",
    id_project: "",
    t2_award_support: "",
    t2_award_description: "",
    other_product_description: "",
    type: "",
    product_type: "",
    title: "",
    inventor: "",
    description: "",
    url: "",
    url_type: "",
    license_assignee: "",
    date_issued: "",
    date_application: "",
    date_application_month: "",
    date_application_day: "",
    date_application_year: "",
    date_issued_month: "",
    date_issued_day: "",
    date_issued_year: "",
    flag_show: "",
    flag_status: "",

    participants: [],
    participants_selected: [],
    non_t2_participants: [],
    non_t2: [],
  },
  filters: {
    page: "",
    id_user: "",
    id_project: "",
    view: "",
    export: "",
    /*-----ADD FILTER FIELDS BELOW-----*/
    title: "",
    type: "",
    flag_status: "",
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.outcomes = [];
    state.outcome = {};
    state.export = [];
    state.filters = {
      page: "",
      view: "",
      export: "",
      id_user: "",
      id_project: "",
      /*-----ADD FILTER FIELDS BELOW-----*/
      title: "",
      type: "",
      flag_status: "",
    };
  },
  CLEAR_LIST(state) {
    state.outcomes = [];
  },
  EXPORT_FILTERS(state, { data }) {
    state.filters.id_project = data.id_project;
    state.filters.export = data.export;
    state.filters.view = 2;
    state.filters.id_user = "";
    state.filters.title = "";
    state.filters.type = "";
    state.filters.flag_status = "";
  },
  FETCH(state, { list }) {
    state.outcomes = list;
  },
  CREATE(state, { data }) {
    state.outcomes.data.unshift(data);
    if (state.entry.view === 1)
      state.outcomes.data = state.outcomes.data.slice(0, 4);

    if (data) {
      let currentTotal = state.outcomes.total;
      state.outcomes.total = currentTotal + 1;
    }
  },
  READ(state, { data }) {
    state.outcome = data;
  },
  UPDATE(state, { data }) {
    const idx = state.outcomes.data.map((item) => item.id).indexOf(data.id);
    state.outcomes.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.outcomes.data.map((item) => item.id).indexOf(id);
    state.outcomes.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.outcome = data;
    state.entry.tab = "tab-1";
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /*-----ADD ENTRY FIELDS BELOW-----*/
    state.entry.id_user = data.id_user;
    state.entry.id_project = data.id_project;
    state.entry.t2_award_support = data.t2_award_support;
    state.entry.t2_award_description = data.t2_award_description;
    state.entry.other_product_description = data.other_product_description;
    state.entry.type = data.type;
    state.entry.product_type = data.product_type;
    state.entry.title = data.title;
    state.entry.inventor = data.inventor;
    state.entry.description = data.description;
    state.entry.url = data.url;
    state.entry.url_type = data.url_type;
    state.entry.license_assignee = data.license_assignee;
    state.entry.date_issued = data.date_issued;
    state.entry.date_application = data.date_application;
    state.entry.date_application_month = data.date_application_month;
    state.entry.date_application_day = data.date_application_day;
    state.entry.date_application_year = data.date_application_year;
    state.entry.date_issued_month = data.date_issued_month;
    state.entry.date_issued_day = data.date_issued_day;
    state.entry.date_issued_year = data.date_issued_year;
    state.entry.flag_show = data.flag_show;
    state.entry.flag_status = data.flag_status;

    state.entry.participants = data.participants;
    state.entry.non_t2_participants = data.non_t2_participants;

    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.tab = "tab-1";
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = "";
    state.entry.view = "";
    /*-----ADD ENTRY FIELDS BELOW-----*/
    state.entry.id_user = "";
    state.entry.id_project = "";
    state.entry.t2_award_support = "";
    state.entry.t2_award_description = "";
    state.entry.other_product_description = "";
    state.entry.type = "";
    state.entry.product_type = "";
    state.entry.title = "";
    state.entry.inventor = "";
    state.entry.description = "";
    state.entry.url = "";
    state.entry.url_type = "";
    state.entry.license_assignee = "";
    state.entry.date_issued = "";
    state.entry.date_application = "";
    state.entry.date_application_month = "";
    state.entry.date_application_day = "";
    state.entry.date_application_year = "";
    state.entry.date_issued_month = "";
    state.entry.date_issued_day = "";
    state.entry.date_issued_year = "";
    state.entry.flag_show = "";
    state.entry.flag_status = "";

    state.entry.participants = [];
    state.entry.participants_selected = [];
    state.entry.non_t2_participants = [];
    state.entry.non_t2 = [];
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  SET_VIEW(state, { data }) {
    state.entry.view = data;
  },
  SET_EXPORT(state, { data }) {
    state.filters.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearList({ commit }) {
    commit("CLEAR_LIST");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/outcomes", data, {
        headers: getHeader(),
      });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/outcomes/create", data, {
        headers: getHeader(),
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/outcomes/${id}`, {
        headers: getHeader(),
      });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(
        `/api/outcomes/update/${data.id}`,
        data,
        { headers: getHeader() }
      );
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/outcomes/delete", data, {
        headers: getHeader(),
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/outcomes/${id}`, {
        headers: getHeader(),
      });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post("/api/outcomes", data, {
        headers: getHeader(),
      });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  },
  setView: ({ commit }, data) => {
    commit("SET_VIEW", { data });
  },
  setExport: ({ commit }, data) => {
    commit("SET_EXPORT", { data });
  },
  exportFilters({ commit }, data) {
    commit("EXPORT_FILTERS", { data });
  },
};

const getters = {
  getOutcome: (state, getters) => state.outcome,
  getOutcomes: (state, getters) => state.outcomes,
  getExport: (state, getters) => state.export,
  getFilters: (state, getters) => state.filters,
  getParticipants: (state, getters) => state.entry.participants,
  getNonT2Participants: (state, getters) => state.entry.non_t2_participants,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
