import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const state = {
  presentations: [],
  presentation: {},
  export: [],
  entry: {
    show: false,
    valid: "",
    mode: "",
    view: "",

    id: "",

    tab: "",

    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_user: "",
    id_project: "",
    title: "",
    author: "",
    url: "",
    type: "",
    type_other: "",
    acknowledgment_fed_support: "",
    support_acknowledgment: "",
    description: "",
    related_t2: "",
    related_t2_description: "",
    event: "",
    location: "",
    cityState: "",
    date: "",
    date_month: "",
    date_year: "",
    date_estimated_published_month: "",
    date_estimated_published_year: "",
    flag_status: "",
    flag_show: "",

    participants: [],
    participants_selected: [],
    non_t2_participants: [],
    non_t2: [],
  },
  filters: {
    page: "",
    view: "",
    export: "",
    id_user: "",
    id_project: "",
    /*-----ADD FILTER FIELDS BELOW-----*/
    title: "",
    type: "",
    flag_status: "",
    flag_user_type: "",
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.presentations = [];
    state.export = [];
    state.filters = {
      page: "",
      view: "",
      export: "",
      id_user: "",
      id_project: "",
      /*-----ADD FILTER FIELDS BELOW-----*/
      title: "",
      type: "",
      flag_status: "",
      flag_user_type: "",
    };
  },
  CLEAR_LIST(state) {
    state.presentations = [];
  },
  EXPORT_FILTERS(state, { data }) {
    state.filters.id_project = data.id_project;
    state.filters.export = data.export;
    state.filters.view = 2;
    state.filters.id_user = "";
    state.filters.title = "";
    state.filters.flag_status = "";
  },
  FETCH(state, { list }) {
    state.presentations = list;
  },
  CREATE(state, { data }) {
    state.presentations.data.unshift(data);
    if (state.entry.view === 1)
      state.presentations.data = state.presentations.data.slice(0, 4);
    if (data) {
      let currentTotal = state.presentations.total;
      state.presentations.total = currentTotal + 1;
    }
  },
  READ(state, { data }) {
    state.presentation = data;
  },
  UPDATE(state, { data }) {
    const idx = state.presentations.data
      .map((item) => item.id)
      .indexOf(data.id);
    state.presentations.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.presentations.data.map((item) => item.id).indexOf(id);
    state.presentations.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.presentation = data;
    state.entry.tab = "tab-1";
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /*-----ADD ENTRY FIELDS BELOW-----*/
    state.entry.id_user = data.id_user;
    state.entry.id_project = data.id_project;
    state.entry.title = data.title;
    state.entry.author = data.author;
    state.entry.url = data.url;
    state.entry.type = data.type;
    state.entry.support_acknowledgment = data.support_acknowledgment;
    state.entry.description = data.description;
    state.entry.related_t2 = data.related_t2;
    state.entry.related_t2_description = data.related_t2_description;
    state.entry.type_other = data.type_other;
    state.entry.acknowledgment_fed_support = data.acknowledgment_fed_support;
    state.entry.event = data.event;
    state.entry.location = data.location;
    state.entry.cityState = data.cityState;
    state.entry.date = data.date;
    state.entry.date_month = data.date_month;
    state.entry.date_year = data.date_year;
    state.entry.date_estimated_published_month =
      data.date_estimated_published_month;
    state.entry.date_estimated_published_year =
      data.date_estimated_published_year;
    state.entry.flag_status = data.flag_status;
    state.entry.flag_show = data.flag_show;

    state.entry.participants = data.participants;
    state.entry.non_t2_participants = data.non_t2_participants;

    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.tab = "tab-1";
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = "";
    state.entry.view = "";
    /*-----ADD ENTRY FIELDS BELOW-----*/
    state.entry.id_user = "";
    state.entry.id_project = "";
    state.entry.title = "";
    state.entry.author = "";
    state.entry.url = "";
    state.entry.type = "";
    state.entry.type_other = "";
    state.entry.support_acknowledgment = "";
    state.entry.description = "";
    state.entry.related_t2 = "";
    state.entry.related_t2_description = "";
    state.entry.acknowledgment_fed_support = "";
    state.entry.event = "";
    state.entry.location = "";
    state.entry.cityState = "";
    state.entry.date = "";
    state.entry.date_month = "";
    state.entry.date_year = "";
    state.entry.date_estimated_published_month = "";
    state.entry.date_estimated_published_year = "";
    state.entry.flag_status = "";
    state.entry.flag_show = "";

    state.entry.participants = [];
    state.entry.participants_selected = [];
    state.entry.non_t2_participants = [];
    state.entry.non_t2 = [];
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  SET_VIEW(state, { data }) {
    state.entry.view = data;
  },
  SET_EXPORT(state, { data }) {
    state.filters.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearList({ commit }) {
    commit("CLEAR_LIST");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/presentations", data, {
        headers: getHeader(),
      });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/presentations/create", data, {
        headers: getHeader(),
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/presentations/${id}`, {
        headers: getHeader(),
      });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(
        `/api/presentations/update/${data.id}`,
        data,
        { headers: getHeader() }
      );
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/presentations/delete", data, {
        headers: getHeader(),
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/presentations/${id}`, {
        headers: getHeader(),
      });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post("/api/presentations", data, {
        headers: getHeader(),
      });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  },
  setView: ({ commit }, data) => {
    commit("SET_VIEW", { data });
  },
  setExport: ({ commit }, data) => {
    commit("SET_EXPORT", { data });
  },
  exportFilters({ commit }, data) {
    commit("EXPORT_FILTERS", { data });
  },
};

const getters = {
  getPresentation: (state, getters) => state.presentation,
  getPresentations: (state, getters) => state.presentations,
  getExport: (state, getters) => state.export,
  getFilters: (state, getters) => state.filters,
  getParticipants: (state, getters) => state.entry.participants,
  getNonT2Participants: (state, getters) => state.entry.non_t2_participants,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
