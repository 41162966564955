import moment from "moment-timezone";

export const mixin = {
  methods: {
    $_getCurrentDate() {
      const today = Date();
      return `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
    },

    $_formatMoney(value) {
      if (value || value == 0) {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
        });

        return formatter.format(value);
      }
    },

    $_formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    $_parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split(/[\\\/|-]/);
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    $_parseDateToArray(date) {
      if (!date) return null;

      return date.split("-");
    },

    $_formatDateYear(date) {
      if (!date) return null;

      const t = date.split("-");

      return Number(t[0]);
    },

    $_formatDateMonth(date) {
      if (!date) return null;

      const t = date.split("-");

      return Number(t[1]);
    },

    $_timestampToDate(value) {
      if (value) {
        return moment(value, moment.ISO_8601, true)
          .tz("EST")
          .format("MM/DD/YYYY");
      }
    },

    $_formatTimestamp(value) {
      if (value) {
        return moment(value, moment.ISO_8601, true)
          .tz("EST")
          .format("MM/DD/YYYY h:mm A");
      }
    },

    $_fellowshipStatus(value) {
      if (value === 1) return "Submitted and Pending";
      if (value === 2) return "Awarded";
      if (value == 0) return "Not Awarded";
    },

    $_fundingStatus(value) {
      if (value === 1) return "Submitted and Pending";
      if (value === 2) return "Awarded";
      if (value == 0) return "Not Awarded";
    },

    $_fundingStatusColor(value) {
      if (value === 1) return "deep-orange--text text--lighten-2";
      if (value === 2) return "success--text";
      if (value == 0) return "nsfgrey--text";
    },

    $_publicationStatus(value) {
      if (value === 5) return "Submitted";
      if (value === 4) return "Under Review";
      if (value === 3) return "Accepted";
      if (value === 2) return "Awaiting Publication";
      if (value === 1) return "Published";
      if (value === 6) return "Other";
      if (value === 0) return "Inactive";
    },

    $_publicationStatusColor(value) {
      if (value === 1) return "success--text";
      if (
        value === 2 ||
        value === 3 ||
        value === 4 ||
        value === 5 ||
        value === 6
      )
        return "deep-orange--text text--lighten-2";
      if (value == 0) return "nsfgrey--text";
    },

    $_patentStatus(value) {
      if (value === 1) return "Submitted";
      if (value === 3) return "Pending";
      if (value === 2) return "Granted";
      if (value === 0) return "Not Granted";
    },

    $_patentStatusColor(value) {
      if (value === 2) return "success--text";
      if (value === 1 || value === 3)
        return "deep-orange--text text--lighten-2";
      if (value == 0) return "nsfgrey--text";
    },

    $_presentationStatus(value) {
      if (value === 5) return "Submitted";
      if (value === 4) return "Under Review";
      if (value === 3) return "Accepted";
      if (value === 2) return "Awaiting Publication";
      if (value === 1) return "Published";
      if (value === 6) return "Other";
    },

    $_presentationStatusColor(value) {
      if (value === 1) return "success--text";
      if (
        value === 2 ||
        value === 3 ||
        value === 4 ||
        value === 5 ||
        value === 6
      )
        return "deep-orange--text text--lighten-2";
    },

    $_projectDateStart(date_start, date_start_month, date_start_year) {
      const [syear, smonth, sday] = date_start.split("-");
      //console.log(syear)
      //console.log(smonth)
      //console.log(sday)
      var sd = new Date(syear, smonth - 1, sday);
      //console.log(sd)
      var cd = new Date(
        date_start_year,
        this.$_monthTextShortToNum(date_start_month) - 1,
        1
      );
      //console.log(cd)
      if (cd < sd) return false;
      return true;
    },

    $_outcomeStatus(type, value) {
      if (type === "License") return value;
      else return this.$_active(value);
    },

    $_outcomeStatusColor(type, value) {
      if (type === "License") {
        if (value === "Pending") return "deep-orange--text text--lighten-2";
        if (value === "Licensed") return "success--text";
        if (value == "None") return "nsfgrey--text";
      } else {
        if (value === "Active") return "success--text";
        if (value === "Inactive") return "error--text";
      }
    },

    $_monthNumToText(val) {
      if (val === 1) return "January";
      if (val === 2) return "February";
      if (val === 3) return "March";
      if (val === 4) return "April";
      if (val === 5) return "May";
      if (val === 6) return "June";
      if (val === 7) return "July";
      if (val === 8) return "August";
      if (val === 9) return "September";
      if (val === 10) return "October";
      if (val === 11) return "November";
      if (val === 12) return "December";
    },

    $_monthNumToTextShort(val) {
      if (val === 1) return "Jan";
      if (val === 2) return "Feb";
      if (val === 3) return "Mar";
      if (val === 4) return "Apr";
      if (val === 5) return "May";
      if (val === 6) return "Jun";
      if (val === 7) return "Jul";
      if (val === 8) return "Aug";
      if (val === 9) return "Sep";
      if (val === 10) return "Oct";
      if (val === 11) return "Nov";
      if (val === 12) return "Dec";
    },

    $_monthTextShortToNum(month) {
      if (month === "Jan") return 1;
      if (month === "Feb") return 2;
      if (month === "Mar") return 3;
      if (month === "Apr") return 4;
      if (month === "May") return 5;
      if (month === "Jun") return 6;
      if (month === "Jul") return 7;
      if (month === "Aug") return 8;
      if (month === "Sep") return 9;
      if (month === "Oct") return 10;
      if (month === "Nov") return 11;
      if (month === "Dec") return 12;
    },

    $_checkNull(value) {
      if (value === null) {
        return "";
      }
      return value;
    },

    $_active(value) {
      if (value == 1) {
        return "Active";
      }
      return "Inactive";
    },

    $_yesNo(value) {
      if (value == 1) {
        return "Yes";
      }
      return "No";
    },

    $_access(value) {
      if (value == 1) {
        return "Has Access";
      }
      return "No Access";
    },

    $_emailStatus(value) {
      if (value == 1) {
        return "Pending";
      }
      if (value == 2) {
        return "Sent";
      }
      return "Canceled";
    },

    $_getUserType(type) {
      if (type === 1) return "Principal Investigator";
      if (type === 2) return "Researcher";
      if (type === 3) return "Trainee";
      if (type === 4) return "Evaluator";
      if (type === 5) return "Coordinator";
      if (type === 6) return "NSF EPSCoR";
      if (type === 7) return "Program Officer";
      if (type === 8) return "RPPR";
    },

    $_getFaqType(type) {
      if (type === 1) return "FAQ";
      if (type === 2) return "Link";
      if (type === 3) return "Post";
      if (type === 4) return "FAQ Header";
    },

    $_uniqueProjectList(projects) {
      let uniqueProjects = [];
      let userTypePrev = "";
      let projectIDPrev = "";
      let userTypeCurrent = "";
      let projectIDCurrent = "";
      let projectStatus = "";
      for (let i = 0; i < projects.length; i++) {
        userTypeCurrent = projects[i].pivot.flag_user_type;
        projectIDCurrent = projects[i].id;
        projectStatus = projects[i].flag_status;
        if (
          (projectIDPrev !== projectIDCurrent && projectStatus === 1) ||
          (projectIDPrev === projectIDCurrent &&
            userTypePrev !== userTypeCurrent &&
            projectStatus === 1)
        ) {
          uniqueProjects.push(projects[i]);
        }
        userTypePrev = projects[i].pivot.flag_user_type;
        projectIDPrev = projects[i].id;
      }

      return uniqueProjects;
    },

    $_uniqueParticipationList(participation) {
      let uniqueParticipation = [];
      let userTypePrev = "";
      let projectIDPrev = "";
      let userTypeCurrent = "";
      let projectIDCurrent = "";
      for (let i = 0; i < participation.length; i++) {
        userTypeCurrent = participation[i].flag_user_type;
        projectIDCurrent = participation[i].id_project;
        if (
          (projectIDPrev !== projectIDCurrent &&
            userTypePrev !== userTypeCurrent) ||
          (projectIDPrev !== projectIDCurrent &&
            userTypePrev === userTypeCurrent)
        ) {
          uniqueParticipation.push(participation[i]);
        }
        userTypePrev = participation[i].flag_user_type;
        projectIDPrev = participation[i].id_project;
      }

      return uniqueParticipation;
    },

    async $_switchUser(id, section) {
      this.$store.dispatch("progressStore/set", true);

      /*Clear stores used on dashboard*/
      this.$store.dispatch("fundingsStore/clearList");
      this.$store.dispatch("publicationsStore/clearList");
      this.$store.dispatch("patentsStore/clearList");
      this.$store.dispatch("presentationsStore/clearList");
      this.$store.dispatch("outcomesStore/clearList");
      this.$store.dispatch("fellowshipsStore/clearList");
      this.$store.dispatch("dissertationsStore/clearList");
      this.$store.dispatch("usersStore/clearTrainees");

      window.localStorage.setItem("adminMode", 1);
      this.$store.dispatch("usersStore/setAdminMode", 1);

      let userType;
      if (section === 4 || section === 6 || section === 9) {
        userType = [3];
      } else if (section === 5) {
        userType = [1, 2];
      } else {
        userType = "";
      }

      this.$store.dispatch("navigationStore/clearNavigation");

      await this.$store.dispatch("usersStore/read", id);
      let user = this.$store.getters["usersStore/getUser"];

      /* Store active user */
      window.localStorage.setItem("activeUser", JSON.stringify(user));
      this.$store.dispatch("usersStore/setActiveUser", user);
      let project = this.$store.getters["usersStore/getActiveProject"];

      /*Clear and remove active project */
      //window.localStorage.removeItem('activeProject');
      //await this.$store.dispatch('usersStore/clearActiveProject');

      /* Grab participation based on active filters */
      let data = {
        id_user: user.id,
        id_project: project.id,
        code: "",
        flag_user_type: userType,
        flag_status: "",
      };
      await this.$store.dispatch("participationStore/fetch", data);
      let participation = this.$store.getters[
        "participationStore/getParticipation"
      ];

      let participationList = this.$_uniqueParticipationList(
        participation.data
      );

      if (participationList.length > 1) {
        this.$router.push({ name: "projectSelector" });
      } else {
        let userType = participation.data[0].flag_user_type;
        /* Store active user type*/
        window.localStorage.setItem("activeUserType", JSON.stringify(userType));
        await this.$store.dispatch("usersStore/setActiveUserType", userType);
        let data = {
          user_type: userType,
        };
        await this.$store.dispatch("navigationStore/fetch", data);
        /* Redirect user to dashboard */
        this.$router.push({ name: "dashboard" });
      }
    },

    async $_openEmail(id) {
      let authUser = this.$store.getters["usersStore/getAuthUser"];
      await this.$store.dispatch("usersStore/read", id);
      let toUser = this.$store.getters["usersStore/getUser"];
      let data = {
        mode: 2,
        section: 2,
        id_user: id,
        to: toUser.email,
        cc: authUser.email,
        subject: "",
        body: "",
        from: authUser.name,
      };
      this.$store.dispatch("emailsStore/openEmail", data);
    },

    $_openChangePassword(id_user) {
      let data = {
        id_user: id_user,
      };
      this.$store.dispatch("usersStore/openPassword", data);
    },

    async $_invite(id) {
      let authUser = this.$store.getters["usersStore/getAuthUser"];
      let data = {
        id_user: id,
        cc: authUser.email,
      };
      let response = await this.$store.dispatch("usersStore/invite", data);
      let message;
      if (response.status === 200) {
        message = {
          color: "success",
          text: "Invitation has been sent.",
        };
      } else {
        message = {
          color: "error",
          text: "There is an issue with sending the invitation at this time.",
        };
      }
      this.$store.commit("messagesStore/setMessage", message);
    },

    async $_loadValueLists(id) {
      let data;
      let activeProject = this.$store.getters["usersStore/getActiveProject"];

      /*5.3 = Users Lists*/
      if (id === 5.3) {
        activeProject = this.$store.getters["projectsStore/getProject"];
        let yearStart = this.$_formatDateYear(activeProject.date_start);
        let year = {
          yearStart: yearStart,
        };
        await this.$store.dispatch("valueListsStore/setProjectYearList", year);
      } else if (
        /*5.x = Users Lists, 10 = Funding, 11 = Publications, 12 = Patents, 13 = Presentations, 14 = Fellowships, 15 = Other Outcomes, 22 = RPPR, 23 = Dissertations*/
        id === 5.2 ||
        id === 5.4 ||
        id === 5.5 ||
        id === 5.6 ||
        id === 5.7 ||
        id === 5.8 ||
        id === 5.9 ||
        id === 5.101 ||
        id === 10 ||
        id === 11 ||
        id === 12 ||
        id === 13 ||
        id === 14 ||
        id === 15 ||
        id === 22 ||
        id === 23
      ) {
        let yearStart = this.$_formatDateYear(activeProject.date_start);
        let year = {
          yearStart: yearStart,
        };
        await this.$store.dispatch("valueListsStore/setProjectYearList", year);
      }

      /*-----Participant Lists-----*/
      /*10 = Funding, 11 = Publications, 12 = Patents, 13 = Presentations, 15 = Other Outcomes*/
      if (id === 10 || id === 11 || id === 12 || id === 13 || id === 15) {
        const researcherData = {
          id_project: activeProject.id,
          type: 1,
        };
        await this.$store.dispatch("participantsStore/fetch", researcherData);

        const traineeData = {
          id_project: activeProject.id,
          type: 2,
        };
        await this.$store.dispatch("participantsStore/fetch", traineeData);

        /*Pulldown for Author of Record - 10 = Funding, 11 = Publications, 12 = Patents, 13 = Presentations, 15 = Outcomes*/
        if (id === 10 || id === 11 || id === 12 || id === 13 || id === 15) {
          await this.$store.dispatch(
            "participantsStore/valueList",
            researcherData
          );
        }
      }

      /*-----Jurisdictions-----*/
      /*10 = Funding, 11 = Publications, 12 = Patents*/
      if (id === 10 || id === 11 || id === 12) {
        await this.$store.dispatch("jurisdictionsStore/valueList");
      }

      /*-----Countries-----*/
      /*12 = Patents*/
      if (id === 12 || id === 22) {
        await this.$store.dispatch("countriesStore/valueList");
      }

      /*-----Institutions-----*/
      /*Disertations*/
      if (id === 23) {
        //await this.$store.dispatch('institutionsStore/valueList');

        let data = {
          id_project: activeProject.id,
          id_jurisdiction: "",
          id_institution: "",
        };

        await this.$store.dispatch("institutionsStore/byProject", data);
        this.show = true;
      }

      /*-----Settings Users List-----*/
      if (id === 5.1) {
        await this.$store.dispatch("projectsStore/valueList");
      }
    },
  },
};
