<template>
  <div v-if="usersStore.appMode === 1 || usersStore.appMode === 3 || !usersStore.appMode">
    <v-tooltip
      bottom
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="openEntry()"
        >
          <v-icon
            color="primary"
          >
            fal fa-question-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Help</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      max-width="1000"
    >
      <v-card
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          {{ this.title }}
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Close
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div v-html="text" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'helpTopicsStore/getHelpField',
  mutationType: 'helpTopicsStore/updateHelpField',
});

export default {
  name: 'Help',
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      helpTopicsStore: state => state.helpTopicsStore
    }),
    ...mapFields([
      'show',
      'title',
      'text',
    ]),

  },
  methods: {
    async openEntry() {
      this.title = ''
      this.text = ''
      /*If current route is any login router*/
      if (this.$route.name === 'login' || this.$route.name === 'login2') {
        /*Return login help message*/
        this.title = 'Login';
        this.text = '<p>To log in, please enter your <strong>institutional email address</strong> and the password that has been provided to you. ' +
        'If you do not remember your password, click <strong>“Forgot My Password”</strong> and a link to reset your password will be emailed to you.</p><p>' +
        'If you have difficulties logging into the system, please email <a href="mailto:help@ilearningi.com">help@ilearningi.com</a></p><p><a href="http://www.ilearningi.com/T2DOP/Getting_Started.pdf" target="_blank">Click here to review the Getting Started Guide</a></p>';
        this.show = true;
      }
      else if (this.$route.name === 'passwordreset') {
        this.title = 'Create Password'
        this.text = '<p>Please enter your <strong>institutional email address</strong> then create a new password that will be used to login to the system.  Clicking <strong>Create Password</strong> will save this password and take you to the login screen.</p><p>' +
        'If you have difficulties, please email <a href="mailto:help@ilearningi.com">help@ilearningi.com</a></p>'
        this.show = true;
      }
      /*Other routes beside login*/
      else {
        const activeUser = this.$store.getters['usersStore/getActiveUserType'];
        let data = {
          route: this.$route.path,
          userType: activeUser
        }
        await this.$store.dispatch('helpTopicsStore/help', data);
        if(this.text){
          this.show = true;
        }
      }
    },

    cancelEntry() {
      this.show = false;
    },
  },
}
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>