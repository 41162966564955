import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  researchers: [],
  trainees:[],
  otherUsers: [],
  rppr: [],
  researchersValueList: [],
  traineesValueList: [],
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',

    entryState: '',
    foundUser: false,
    /*-----ADD ENTRY FIELDS BELOW-----*/
    participationID: '',
    userID: '',
    id_project: '',
    researcherID: '',
    date_start_month: '',
    date_start_year: '',
    date_end_month: '',
    date_end_year: '',
    advisorList: [],
    flag_status: '',
    flag_contact_pi: '',

    researchers: [],
    trainees: [],

    participants: [],

    participants_selected: [],

    non_t2: [],
  },
  filters: {
    page: '',
    id_project: '',
    /*-----ADD FILTER FIELDS BELOW-----*/
    id_funding: '',
    id_publication: '',
    id_patent: '',
    id_presentation: '',

    name_first: '',
    name_last: '',

    researcher_id_user: '',
    researcher_name_first: '',
    researcher_name_last: '',

    trainee_id_user: '',
    trainee_name_first: '',
    trainee_name_last: '',

    other_id_user: '',
    other_name_first: '',
    other_name_last: '',

    type: '',
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.researchers = [];
    state.trainees = [];
    state.otherUsers = [];
    state.rppr = [];
    state.researchersValueList = [];
    state.traineesValueList = [];

    state.entry = {
      show: false,
      valid: '',
      mode: '',
      id: '',
  
      entryState: '',
      foundUser: false,
      /*-----ADD ENTRY FIELDS BELOW-----*/
      participationID: '',
      userID: '',
      id_project: '',
      researcherID: '',
      date_start_month: '',
      date_start_year: '',
      date_end_month: '',
      date_end_year: '',
      advisorList: [],
      flag_status: '',
      flag_contact_pi: '',

      researchers: [],
      trainees: [],

      participants: [],

      participants_selected: [],

      non_t2: [],
    }
    state.filters = {
      page: '',
      /*-----ADD FILTER FIELDS BELOW-----*/
      id_funding: '',
      id_publication: '',
      id_patent: '',
      id_presentation: '',
  
      name_first: '',
      name_last: '',

      researcher_id_user: '',
      researcher_name_first: '',
      researcher_name_last: '',
  
      trainee_id_user: '',
      trainee_name_first: '',
      trainee_name_last: '',
  
      other_id_user: '',
      other_name_first: '',
      other_name_last: '',
  
      type: '',
      id_project: ''
    };
  },
  CLEAR_PARTICIPANTS_SELECTED(state) {
    state.entry.participants_selected = [];
    state.entry.participants = [];
  },
  CLEAR_RESEARCHERS(state) {
    state.researchers = [];
  },
  FETCH(state, { list }) {
    state.participants = list;
  },
  FETCH_RESEARCHERS(state, { list }) {
    state.researchers = list;
  },
  FETCH_TRAINEES(state, { list }) {
    state.trainees = list;
  },
  FETCH_OTHER_USERS(state, { list }) {
    state.otherUsers = list;
  },
  FETCH_RPPR(state, { list }) {
    state.rppr = list;
  },
  CREATE(state, { data }) {
    state.participants.data.push(data);
  },
  READ(state, { data }) {
    state.participant = data;
  },
  UPDATE(state, { data }) {
    const idx = state.participants.data.map(item => item.id).indexOf(data.id);
    state.participants.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.participants.data.map(item => item.id).indexOf(id);
    state.participants.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;

    state.entry.name_first = data.name_first;
    state.entry.name_last = data.name_last;
    state.entry.email = data.email;
    state.entry.password = data.password;
    state.entry.flag_type = data.flag_type;
    state.entry.flag_admin = data.flag_admin;

    state.entry.date_start_month = data.date_start_month;
    state.entry.date_start_year = data.date_start_year;
    state.entry.date_end_month = data.date_end_month;
    state.entry.date_end_year = data.date_end_year;
    state.entry.advisors = data.advisors;
    state.entry.flag_status = data.flag_status;
    state.entry.flag_contact_pi = data.flag_contact_pi;

    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.entryState = 0;
    state.entry.foundUser = false;

    state.entry.name_first = '';
    state.entry.name_last = '';
    state.entry.email = '';
    state.entry.password = '';
    state.entry.confirmPassword = '';
    state.entry.flag_type = '';
    state.entry.flag_admin = '';
    
    state.entry.date_start_month = '';
    state.entry.date_start_year = '';
    state.entry.date_end_month = '';
    state.entry.date_end_year = '';
    state.entry.advisorList = [];
    state.entry.flag_status = '';
    state.entry.flag_contact_pi = '';
    
    state.entry.researchers = [];
    state.entry.trainees = [];

    state.entry.participants = [];

    state.entry.participants_selected = [];

    state.entry.non_t2 = [];

    state.filters = {
      page: '',

      /*-----ADD FILTER FIELDS BELOW-----*/
      id_funding: '',
      id_publication: '',
      id_patent: '',
      id_presentation: '',
  
      name_first: '',
      name_last: '',
  
      researcher_id_user: '',
      researcher_name_first: '',
      researcher_name_last: '',
  
      trainee_id_user: '',
      trainee_name_first: '',
      trainee_name_last: '',
  
      other_id_user: '',
      other_name_first: '',
      other_name_last: '',
    };
  },
  VALUE_LIST(state, { list }) {
    state.participantsValueList = list;
  },
  RESEARCHER_VALUE_LIST(state, { list }) {
    state.researchersValueList = list;
  },
  TRAINEE_VALUE_LIST(state, { list }) {
    state.traineesValueList = list;
  },
  SET_ENTRY_STATE(state, num) {
    state.entry.entryState = num;
  },
  SET_PARTICIPATION_DETAILS(state, {data}) {
    state.entry.id = data.id;
    state.entry.userID = data.id_user;
    state.entry.id_project = data.id_project;
    state.entry.date_start_month = data.date_start_month;
    state.entry.date_start_year = data.date_start_year;
    state.entry.date_end_month = data.date_end_month;
    state.entry.date_end_year = data.date_end_year;
    state.entry.advisors = data.advisors;
    state.entry.flag_status = data.flag_status;
    state.entry.flag_contact_pi = data.flag_contact_pi;
    state.entry.show = true;
  },
  SET_FOUND_USER(state, {data}) {
    state.entry.foundUser = true;
    state.entry.userID = data.id;
    state.entry.name = data.name;
    state.entry.email = data.email;
    // password: '',
    // confirmPassword: '',
    state.entry.flag_type = data.flag_type;
    state.entry.flag_admin = data.flag_admin;

    state.entry.name_first = data.name_first;
    state.entry.name_last = data.name_last;
    state.entry.email = data.email;
  },
  SET_PARTICIPANTS(state, {list}) {
    state.entry.participants_selected = [];
    let newData = '';
    list.forEach(item => {
      state.entry.participants.push(item.id);
      newData = {
        id: item.id,
        name_reverse: item.name_reverse,
        role: item.pivot.role ? item.pivot.role : 0,
        type: item.pivot.type ?item.pivot.type : 1
      }
      state.entry.participants_selected.push(newData);
    });
  },
  SET_NON_T2_PARTICIPANTS(state, {list}) {
    state.entry.non_t2 = [];
    let newData = '';
    list.forEach(item => {
      newData = {
        id: item.id,
        name: item.name,
        institution: item.institution,
        role: item.role,
        position: item.position,
        institution_type: item.institution_type,
        institution_location: item.institution_location
      }
      state.entry.non_t2.push(newData);
    });
  },
  ADD_PARTICIPANT(state, {data}) {
    state.entry.participants.push(data.id);
    state.entry.participants_selected.push(data);
  },
  REMOVE_PARTICIPANT(state, {data}) {
    let idx = state.entry.participants.indexOf(data.id);
    state.entry.participants.splice(idx, 1);
    idx = state.entry.participants_selected.map(item => item.id).indexOf(data.id);
    state.entry.participants_selected.splice(idx, 1);
  },
  ADD_NON_T2(state) {
    let item = {
      id: '',
      name: '',
      institution: '',
      role: '',
      position: '',
      institution_type: '',
      institution_location: ''
    };

    state.entry.non_t2.push(item);
  },
  REMOVE_NON_T2(state, {id}) {
    state.entry.non_t2.splice(id, 1);
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
  ADD_AUTH_USER(state, {data}) {
    state.researchersValueList.unshift(data);
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/participants', data, { headers: getHeader() });
      if (data.type === 1) commit('FETCH_RESEARCHERS', { list: response.data });
      else if (data.type === 2) commit('FETCH_TRAINEES', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/participants/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/participants/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/participants/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/participants/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post(`/api/participants/valuelist`, data, { headers: getHeader() });
      if (data.type === 1) commit('RESEARCHER_VALUE_LIST', { list: response.data });
      else if (data.type === 2) commit('TRAINEE_VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
  async check({commit}, data) {
    try {
      const response = await axios.post(`api/users/participationcheck`, data, { headers: getHeader() });
      if (response.data.user) {
        if (response.data.participant === 1) {
          commit('SET_ENTRY_STATE', 1); // Edit participation
          commit('SET_PARTICIPATION_DETAILS', { data: response.data.participant_details });
        }
        else {
          commit('SET_ENTRY_STATE', 2); // Add participation for found user
        }
        commit('SET_FOUND_USER', { data: response.data.user});
      }
      else {
        commit('SET_ENTRY_STATE', 3); // Add user and participation
      }
    } catch(error) {

    }
  },
  async traineeCheck({commit}, data) {
    try {
      const response = await axios.post(`api/users/participationtraineecheck`, data, { headers: getHeader() });
      if (response.data.user) {
        if (response.data.participant === 1) {
          commit('SET_ENTRY_STATE', 1); // Edit participation
          commit('SET_PARTICIPATION_DETAILS', { data: response.data.participant_details });
        }
        else {
          commit('SET_ENTRY_STATE', 2); // Add participation for found user
        }
        commit('SET_FOUND_USER', { data: response.data.user});
      }
      else {
        commit('SET_ENTRY_STATE', 3); // Add user and participation
      }
    } catch(error) {

    }
  },
  async checkByID({commit}, data) {
    try {
      const response = await axios.post('api/users/participationcheckbyid', data, { headers: getHeader() });
      commit('SET_ENTRY_STATE', 1); // Edit participation
      commit('SET_PARTICIPATION_DETAILS', { data: response.data.participant_details });
      commit('SET_FOUND_USER', { data: response.data.user});
    } catch(error) {

    }
  },
  async researchers({commit}, data) {
    try {
      const response = await axios.post('api/project/researchers', data, { headers: getHeader() });
      commit('FETCH_RESEARCHERS', {list: response.data});
    } catch(error) {
    }
  },
  async trainees({commit}, data) {
    try {
      const response = await axios.post('api/project/trainees', data, { headers: getHeader() });
      commit('FETCH_TRAINEES', {list: response.data});
    } catch(error) {
    }
  },
  async editParticipation({commit}) {
    try {
      const response = await axios.post('api/project/participant/edit', data, {headers:getHeader()});

    } catch(error) {

    }
  },
  async setParticipants({commit}, list) {
    try {
      commit('SET_PARTICIPANTS', {list: list});
    } catch (error) {

    }
  },
  async setNonT2Participants({commit}, list) {
    try {
      commit('SET_NON_T2_PARTICIPANTS', {list: list});
    } catch (error) {

    }
  },
  addParticipant({commit}, data) {
    try {
      commit('ADD_PARTICIPANT', {data: data});
    } catch (e) {

    }
  },
  removeParticipant({commit}, data) {
    try {
      commit('REMOVE_PARTICIPANT', {data: data});
    } catch (e) {
      throw e.toString();
    }
  },
  addNonT2({commit}) {
    try {
      commit('ADD_NON_T2');
    } catch (e) {

    }
  },
  removeNonT2({commit}, id) {
    try {
      commit('REMOVE_NON_T2', {id: id})
    } catch (e) {

    }
  },
  clearParticipantsSelected({commit}) {
    try {
      commit('CLEAR_PARTICIPANTS_SELECTED');
    } catch (e) {

    }
  },
  clearResearchers({commit}) {
    try {
      commit('CLEAR_RESEARCHERS');
    } catch (e) {

    }
  },
  addAuthUser({commit}, data) {
    try {
      commit('ADD_AUTH_USER', {data: data});
    } catch (e) {

    }
  },
};

const getters = {
  getParticipant: (state, getters) => state.participant,
  getResearchers: (state, getters) => state.researchers,
  getTrainees: (state, getters) => state.trainees,
  getFilters: (state, getters) => state.filters,
  getResearcherFilters: (state, getters) => state.filters.researcher_filters,
  getTraineeFilters: (state, getters) => state.filters.trainee_filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getParticipants: (state, getters) => state.entry.participants,
  getParticipantsSelected: (state, getters) => state.entry.participants_selected,
  getNonT2: (state, getters) => state.entry.non_t2,
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
