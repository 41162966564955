<template>
  <!--Change Password Dialog-->
  <v-dialog v-model="show" persistent max-width="600">
    <v-card flat>
      <v-form ref="entryForm" v-model="valid" lazy-validation>
        <v-card-title class="text-h5 secondary--text">
          Change Password
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left> fal fa-times </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="password"
                  prepend-icon="fal fa-lock"
                  label="Password"
                  placeholder=" "
                  type="password"
                  required
                  :rules="rules.password"
                />
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="passwordConfirmation"
                  prepend-icon="fal fa-lock"
                  label="Confirm Password"
                  placeholder=" "
                  type="password"
                  required
                  :rules="rules.passwordConfirmation"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-spacer />
          <v-btn rounded block color="primary" @click="saveEntry()">
            <v-icon small left>
              fal fa-check
            </v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { getHeader } from "@/env";

const { mapFields } = createHelpers({
  getterType: "usersStore/getPasswordField",
  mutationType: "usersStore/updatePasswordField",
});

export default {
  name: "Password",
  data() {
    return {
      rules: {
        password: [
          (v) => !!v || "Required",
          (v) =>
            (v && v.length >= 8) || "Password must have at least 8 characters",
        ],
        passwordConfirmation: [
          (v) => !!v || "Required",
          (v) => v === this.password || "Passwords must match",
        ],
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
    }),
    ...mapFields([
      "show",
      "valid",

      "id_user",
      "password",
      "passwordConfirmation",
    ]),
  },
  methods: {
    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        /* Grab data from update password form */
        const data = {
          id: this.id_user,
          password: this.password,
        };
        /* POST user data to Auth API */
        axios
          .post("/api/user/password", data, { headers: getHeader() })
          .then((response) => {
            /* Show success notification */
            let data = {
              color: "success",
              text: "Password was reset successfully",
            };
            this.$store.commit("messagesStore/setMessage", data);
          })
          .catch((error) => {
            /* Show error notification */
            let data = {
              color: "error",
              text: "Password reset error.",
            };
            this.$store.commit("messagesStore/setMessage", data);
          });
        this.cancelEntry();
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
