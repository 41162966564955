import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const state = {
  rppr: [],
  export: [],
  entry: {
    show: false,
    valid: "",
    mode: "",
    id: "",

    participation: [],

    index: "",
    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_project: "",
    id_user: "",
    id_year: "",
    year_schooling_completed: "",
    home_institution: "",
    gov_fiscal_year: [],
    percent_effort: "",
    appointment_term: "",
    appointment_term_other: "",
    funding_support: "",
    person_months: "",
    contribution: "",
    collab_internationally: "",
    collab_countries: [],
    travel_internationally: "",
    travel_countries: [
      {
        country: "",
        years: "",
        months: "",
        days: "",
      },
    ],
    flag_status: "",
  },
  filters: {
    page: "",
    sectionStored: "",
    export: "",

    activeUser: "",

    rppr_id: "",
    rppr_name_first: "",
    rppr_name_last: "",
    rppr_flag_user_type: "",
    email: "",
  },
  user: {
    show: false,
    valid: false,
    mode: "",
    id: "",

    sectionStored: "",

    name_first: "",
    name_last: "",
    email: "",
    emailAddress: "",
    password: "",
    passwordConfirmation: "",
    flag_status: "",
    flag_admin: "",
    flag_hide: "",
    flag_show: "",
    emailCheck: "",

    id_participation: "",
    id_project: "",
    date_start_project: "",
    date_start_month: "",
    date_start_year: "",
    date_end_month: "",
    date_end_year: "",
    flag_contact_pi: "",
    flag_user_type: "",
    advisors: [],
    sendInvite: "",
    flag_email: "",
    classification: "",
    classification_other: "",
  },
};

const mutations = {
  CLEAR_STORE(state) {
    (state.rppr = []),
      (state.entry = {
        valid: "",
        mode: "",
        id: "",
        participation: [],
        index: "",
        /*-----ADD ENTRY FIELDS BELOW-----*/
        id_project: "",
        id_user: "",
        id_year: "",
        year_schooling_completed: "",
        home_institution: "",
        gov_fiscal_year: [],
        percent_effort: "",
        appointment_term: "",
        appointment_term_other: "",
        funding_support: "",
        person_months: "",
        contribution: "",
        collab_internationally: "",
        collab_countries: [],
        travel_internationally: "",
        travel_countries: [
          {
            country: "",
            years: "",
            months: "",
            days: "",
          },
        ],
        flag_status: "",
      }),
      (state.filters = {
        page: "",
        sectionStored: "",
        export: "",

        activeUser: "",

        rppr_id: "",
        rppr_name_first: "",
        rppr_name_last: "",
        rppr_flag_user_type: "",
        email: "",
      }),
      (state.user = {
        show: false,
        valid: false,
        mode: "",
        id: "",

        sectionStored: "",

        name_first: "",
        name_last: "",
        email: "",
        emailAddress: "",
        password: "",
        passwordConfirmation: "",
        flag_status: "",
        flag_admin: "",
        flag_hide: "",
        flag_show: "",
        emailCheck: "",

        id_participation: "",
        id_project: "",
        date_start_project: "",
        date_start_month: "",
        date_start_year: "",
        date_end_month: "",
        date_end_year: "",
        flag_contact_pi: "",
        flag_user_type: "",
        advisors: [],
        sendInvite: "",
        flag_email: "",
        classification: "",
        classification_other: "",
      });
  },
  EXPORT_FILTERS(state, { data }) {
    state.filters.id_project = data.id_project;
    state.filters.export = data.export;
  },
  FETCH(state, { list }) {
    state.rppr = list;
  },
  CREATE(state, { data }) {
    state.rppr.data.unshift(data);
  },
  READ(state, { data }) {
    state.rppr = data;
  },
  UPDATE(state, { data }) {
    const idx = state.rppr.data.map((item) => item.id).indexOf(data.id);
    state.rppr.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.rppr.data.map((item) => item.id).indexOf(id);
    state.rppr.data.splice(idx, 1);
  },
  USER_ENTRY(state) {
    state.user.valid = true;
    state.user.mode = 2;
    state.user.id = "";

    state.user.sectionStored = "";

    state.user.name_first = "";
    state.user.name_last = "";
    state.user.email = "";
    state.user.emailAddress = "";
    state.user.password = "";
    state.user.passwordConfirmation = "";
    state.user.flag_status = 1;
    state.user.flag_admin = 0;
    state.user.flag_hide = 0;
    state.user.emailCheck = "";
    state.user.flag_show = 1;

    state.user.id_participation = "";
    //state.user.id_project = '';
    state.user.date_start_project = "";
    state.user.date_start_month = "";
    state.user.date_start_year = "";
    state.user.date_end_month = "";
    state.user.date_end_year = "";
    state.user.flag_contact_pi = "";
    state.user.flag_user_type = "";
    state.user.advisors = [];
    state.user.sendInvite = "";
    state.user.flag_email = "";
  },
  RESET_USER_ENTRY(state) {
    state.user.emailCheck = "";
    state.user.valid = true;
    state.user.mode = 1;
    state.user.id = "";

    state.user.sectionStored = "";

    state.user.name_first = "";
    state.user.name_last = "";
    state.user.email = "";
    state.user.emailAddress = "";
    state.user.password = "";
    state.user.passwordConfirmation = "";
    state.user.flag_status = 1;
    state.user.flag_admin = 0;
    state.user.flag_hide = 0;
    state.user.flag_show = 1;

    state.user.id_participation = "";
    state.user.id_project = "";
    state.user.date_start_project = "";
    state.user.date_start_month = "";
    state.user.date_start_year = "";
    state.user.date_end_month = "";
    state.user.date_end_year = "";
    state.user.flag_contact_pi = "";
    state.user.flag_user_type = "";
    state.user.advisors = [];
    state.user.sendInvite = "";
    state.user.flag_email = "";
  },
  NEW_ENTRY(state, { data }) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id_project = data.id_project;
    state.entry.id_user = data.id_user;
    state.entry.id_year = data.id_year;
    state.entry.participation = data.participation;
    state.entry.show = true;
  },
  SET_PARTICIPATION(state, { data }) {
    state.entry.participation = data.participation;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.id_project = data.id_project;
    state.entry.id_user = data.id_user;
    state.entry.id_year = data.id_year;
    state.entry.year_schooling_completed = data.year_schooling_completed;
    state.entry.home_institution = data.home_institution;
    state.entry.gov_fiscal_year = data.gov_fiscal_year;
    state.entry.percent_effort = data.percent_effort;
    state.entry.appointment_term = data.appointment_term;
    state.entry.appointment_term_other = data.appointment_term_other;
    state.entry.funding_support = data.funding_support;
    state.entry.person_months = data.person_months;
    state.entry.contribution = data.contribution;
    state.entry.collab_internationally = data.collab_internationally;
    state.entry.collab_countries = data.collab_countries;
    state.entry.travel_internationally = data.travel_internationally;
    state.entry.travel_countries = data.travel_countries
      ? data.travel_countries
      : [];
    state.entry.flag_status = data.flag_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = "";
    state.entry.id_project = "";
    state.entry.date_start_project = "";
    state.entry.id_user = "";
    state.entry.id_year = "";
    state.entry.year_schooling_completed = "";
    state.entry.home_institution = "";
    state.entry.gov_fiscal_year = "";
    state.entry.percent_effort = "";
    state.entry.appointment_term = "";
    state.entry.appointment_term_other = "";
    state.entry.funding_support = "";
    state.entry.person_months = "";
    state.entry.contribution = "";
    state.entry.collab_internationally = "";
    state.entry.collab_countries = "";
    state.entry.travel_internationally = "";
    state.entry.travel_countries = [];
    state.entry.participation = [];
    state.entry.flag_status = 1;
  },
  ADD_ITEM(state) {
    const data = {
      country: "",
      years: "",
      months: "",
      days: "",
    };
    state.entry.travel_countries.push(data);
  },
  SEARCH_EMAIL(state, { data }) {
    state.user.emailCheck = 1;
    state.user.valid = true;
    state.user.mode = data.id ? 2 : 1;
    state.user.id = data.id;

    state.user.email = state.user.emailAddress;
    state.user.name_first = data.name_first;
    state.user.name_last = data.name_last;
    state.user.password = "";
    state.user.passwordConfirmation = "";
    state.user.flag_status = data.flag_status ? data.flag_status : 1;
    state.user.flag_admin = data.flag_admin ? data.flag_admin : 0;
    state.user.flag_hide = data.flag_hide ? data.flag_hide : 0;
    state.user.flag_show = data.flag_show ? data.flag_show : 1;
    state.user.id_participation = data.participation
      ? data.participation.id
      : "";
    state.user.flag_email = 0;
    state.user.show = true;
  },
  REMOVE_ITEM(state, { id }) {
    state.entry.travel_countries.splice(id, 1);
  },
  CLEAR_RPPR(state) {
    state.rppr = [];
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  SET_EXPORT(state, { data }) {
    state.filters.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateUserEntryField(state, field) {
    updateField(state.user, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/users", data, {
        headers: getHeader(),
      });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/rppr/create", data, {
        headers: getHeader(),
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async createUser({ commit }, data) {
    try {
      const response = await axios.post("/api/users/create", data, {
        headers: getHeader(),
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/rppr/${id}`, {
        headers: getHeader(),
      });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/rppr/update/${data.id}`, data, {
        headers: getHeader(),
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/rppr/delete", data, {
        headers: getHeader(),
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/rppr/${id}`, {
        headers: getHeader(),
      });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async readByEmail({ commit }, data) {
    try {
      const response = await axios.post("/api/users/search/email", data, {
        headers: getHeader(),
      });
      commit("SEARCH_EMAIL", { data: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post("/api/rppr/export", data, {
        headers: getHeader(),
      });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  addItem({ commit }) {
    commit("ADD_ITEM");
  },
  removeItem({ commit }, id) {
    commit("REMOVE_ITEM", { id });
  },
  newEntry({ commit }, data) {
    commit("NEW_ENTRY", { data: data });
  },
  setParticipation({ commit }, data) {
    commit("SET_PARTICIPATION", { data: data });
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  },
  resetUserEntry({ commit }) {
    commit("RESET_USER_ENTRY");
  },
  clearRppr({ commit }) {
    commit("CLEAR_RPPR");
  },
  newEntry({ commit }, data) {
    commit("NEW_ENTRY", { data: data });
  },
  setExport: ({ commit }, data) => {
    commit("SET_EXPORT", { data });
  },
  exportFilters({ commit }, data) {
    commit("EXPORT_FILTERS", { data });
  },
};

const getters = {
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getUserEntry: (state, getters) => state.user,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getUserEntryField(state) {
    return getField(state.user);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
