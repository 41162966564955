<template>
  <v-app>
    <!-- Message -->
    <message />

    <!--Change Password-->
    <password />

    <!--Navigation-->
    <v-navigation-drawer
      v-if="usersStore.authUser"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      floating
      class="accent"
      width="240"
    >
      <v-container v-if="!$vuetify.breakpoint.lgAndUp" class="py-0" fluid>
        <v-row dense>
          <v-col cols="12">
            <!--Logo-->
            <v-card flat class="pt-1" color="accent">
              <v-img
                v-if="$vuetify.theme.dark === false"
                contain
                height="60px"
                :src="require('@/assets/logo_small_light.png')"
              />
              <v-img
                v-if="$vuetify.theme.dark === true"
                contain
                height="60px"
                :src="require('@/assets/logo_small_dark.png')"
              />
            </v-card>
          </v-col>
          <v-col cols="12">
            <p class="text-h6 nsfgrey--text text-center">
              Data Outcomes Portal (DOP)
              <span v-if="usersStore.appMode === 1">{{
                usersStore.activeProject
                  ? ": " + usersStore.activeProject.short_name
                  : ""
              }}</span>
            </p>
          </v-col>
        </v-row>
      </v-container>

      <!--Main Navigation Upper Section-->
      <v-list
        v-if="
          usersStore.appMode === 1 && currentRouteName !== 'projectSelector'
        "
        class="pt-4"
        rounded
      >
        <div v-for="(item, index) in navigationStore.navigation" :key="index">
          <v-list-item
            v-if="
              (item.flag_section === 1 &&
                uniqueProjects.length > 1 &&
                item.title === 'Projects') ||
                (item.flag_section === 1 && item.title !== 'Projects')
            "
            :key="item.id"
            :to="{ name: item.url }"
            active-class="primary--text font-weight-bold"
          >
            <v-list-item-action>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-if="item.json_subnav">
            <v-list-item
              v-for="(subnav, index) in item.json_subnav.subnav"
              v-if="currentRoutePath === item.json_subnav.path"
              :key="index"
              :to="{ name: subnav.url }"
              active-class="primary--text font-weight-bold"
            >
              <v-list-item-content>
                <v-list-item-title class="text-body-2 ml-4">
                  &#8226; {{ subnav.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>

      <!--Main Navigation Divider-->
      <v-divider
        v-if="
          usersStore.appMode === 1 &&
            currentRouteName !== 'projectSelector' &&
            navigationStore.navigation.length > 0
        "
      />

      <!--Main Navigation Lower Section-->
      <v-list
        v-if="
          usersStore.appMode === 1 && currentRouteName !== 'projectSelector'
        "
        class="pt-4"
        rounded
      >
        <!--Stop Admin Button-->
        <v-list-item
          v-if="
            usersStore.adminMode === 1 && navigationStore.navigation.length > 0
          "
          @click="stopAdminMode()"
        >
          <v-list-item-action>
            <v-icon color="error">
              fal fa-power-off
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span class="error--text font-weight-bold">Stop Admin Mode </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div v-for="(item, index) in navigationStore.navigation" :key="index">
          <v-list-item
            v-if="item.flag_section === 3"
            :key="item.id"
            :to="{ name: item.url }"
            active-class="primary--text font-weight-bold"
          >
            <v-list-item-action>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                v-if="item.id === 19 && usersStore.adminMode === 1"
              >
                Profile
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-if="item.json_subnav">
            <v-list-item
              v-for="(subnav, index) in item.json_subnav.subnav"
              v-if="currentRoutePath === item.json_subnav.path"
              :key="index"
              :to="{ name: subnav.url }"
              active-class="primary--text font-weight-bold"
            >
              <v-list-item-content>
                <v-list-item-title class="text-body-2 ml-4">
                  &#8226; {{ subnav.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>

      <!--Admin Navigation-->
      <v-list v-if="usersStore.appMode === 2" class="pt-4" rounded>
        <div
          v-for="(item, index) in navigationStore.navigationAdmin"
          :key="index"
        >
          <v-list-item
            v-if="item.flag_section === 2"
            :key="item.id"
            :to="{ name: item.url }"
            active-class="primary--text font-weight-bold"
          >
            <v-list-item-action>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-if="item.json_subnav">
            <v-list-item
              v-for="(subnav, index) in item.json_subnav.subnav"
              v-if="currentRoutePath === item.json_subnav.path"
              :key="index"
              :to="{ name: subnav.url }"
              active-class="primary--text font-weight-bold"
            >
              <v-list-item-content>
                <v-list-item-title class="text-body-2 ml-4">
                  &#8226; {{ subnav.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>

    <!--Top Toolbar-->
    <v-app-bar
      v-if="usersStore.authUser"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      flat
      class="accent"
    >
      <!--Progress Bar-->
      <v-progress-linear
        :active="progressStore.progress"
        :indeterminate="progressStore.progress"
        height="6"
        absolute
        top
        color="success"
      />

      <!--Progress Pop Up-->
      <v-dialog
        v-model="progressStore.progress"
        max-width="290"
        persistent
        hide-overlay
      >
        <v-card color="success" dark>
          <v-card-text>
            Loading
            <v-progress-linear indeterminate color="white" class="mb-0" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-app-bar-nav-icon
        v-if="usersStore.authUser"
        class="hidden-lg-and-up"
        color="primary"
        @click.stop="drawer = !drawer"
      />
      <!--Logo-->
      <v-card
        v-if="$vuetify.breakpoint.lgAndUp"
        flat
        class="pt-1"
        color="accent"
      >
        <v-img
          v-if="$vuetify.theme.dark === false"
          contain
          height="60px"
          :src="require('@/assets/logo_small_light.png')"
        />
        <v-img
          v-if="$vuetify.theme.dark === true"
          contain
          height="60px"
          :src="require('@/assets/logo_small_dark.png')"
        />
      </v-card>
      <span
        v-if="$vuetify.breakpoint.lgAndUp"
        class="text-h6 nsfgrey--text"
        text-md-left
        >Data Outcomes Portal (DOP)
        <span v-if="usersStore.appMode === 1">{{
          usersStore.activeProject
            ? ": " + usersStore.activeProject.short_name
            : ""
        }}</span></span
      >

      <v-spacer />

      <!--User Name When Logged In-->
      <span
        v-if="usersStore.adminMode === 1"
        class="error--text text-subtitle-1 font-weight-bold"
        text-md-right
      >
        Viewing Portal For {{ usersStore.activeUser.name }}
      </span>
      <span v-else class="secondary--text text-subtitle-1" text-md-right>
        {{ usersStore.authUser.name }}
      </span>

      <!--User Profile Button-->
      <v-menu v-if="usersStore.authUser" open-on-click offset-y left>
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="32px" tile>
              <v-icon color="primary">
                fal fa-user-circle
              </v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="usersStore.adminMode !== 1"
            @click.native="openChangePassword(usersStore.authUser.id)"
          >
            <v-list-item-title class="clickable primary--text">
              <v-icon color="primary">
                fal fa-key
              </v-icon>
              Change Password
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click.native="handleLogout">
            <v-list-item-title class="clickable primary--text">
              <v-icon color="primary">
                fal fa-sign-out-alt
              </v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <help />

      <email />

      <!--Settings Button-->
      <v-btn
        v-if="usersStore.authUser.flag_admin === 1 && usersStore.appMode !== 2"
        icon
        @click="switchAppModes(2)"
      >
        <v-icon color="primary">
          fal fa-tools
        </v-icon>
      </v-btn>

      <!--Home Button-->
      <v-btn
        v-if="usersStore.authUser.flag_admin === 1 && usersStore.appMode === 2"
        icon
        @click="switchAppModes()"
      >
        <v-icon color="primary">
          fal fa-tachometer-alt
        </v-icon>
      </v-btn>
    </v-app-bar>

    <!--Top Toolbar-->
    <v-app-bar v-else app flat class="accent">
      <v-spacer />
      <help />
    </v-app-bar>

    <!--Content-->
    <v-main>
      <v-container fluid class="accent fill-height">
        <v-row dense class="fill-height ma-0">
          <router-view />
        </v-row>
      </v-container>
    </v-main>

    <!--Footer-->
    <v-footer app color="accent">
      <v-btn v-if="$vuetify.theme.dark === true" icon @click="switchTheme()">
        <v-icon color="primary">
          fal fa-sun
        </v-icon>
      </v-btn>

      <v-btn v-else icon @click="switchTheme()">
        <v-icon color="primary">
          fal fa-moon-stars
        </v-icon>
      </v-btn>

      <v-spacer />

      <span
        class="text-sm-left grey--text"
        :class="$vuetify.theme.dark === false ? 'text--darken-1' : ''"
        >{{ companyName }} &copy; {{ new Date().getFullYear() }} v{{
          applicationVersion
        }}</span
      >
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { company, siteVersion, getHeader } from "@/env";
import { mixin } from "@/mixins/mixin.js";
import Message from "@/components/Message";
import Help from "@/components/Help";
import Email from "@/components/Email";
import Password from "@/components/Password";

export default {
  components: {
    Message,
    Help,
    Email,
    Password,
  },
  props: {
    source: String,
  },
  mixins: [mixin],
  data() {
    return {
      companyName: company,
      applicationVersion: siteVersion,
      helpDialog: false,
      help: [],
      drawer: null,
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      helpTopicsStore: (state) => state.helpTopicsStore,
      messagesStore: (state) => state.messagesStore,
      progressStore: (state) => state.progressStore,
      navigationStore: (state) => state.navigationStore,
    }),
    currentRouteName() {
      return this.$route.name;
    },
    currentRoutePath() {
      const currentPath = this.$route.path;
      const currentPathSplit = currentPath.split("/");
      return currentPathSplit[1];
    },
    uniqueProjects() {
      const activeUser = this.$store.getters["usersStore/getActiveUser"];
      if (activeUser) {
        return this.$_uniqueProjectList(activeUser.projects);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$vuetify.theme.dark = false;
      if (this.$route.name === "logout") {
        this.handleLogout();
      } else {
        const userObj = JSON.parse(window.localStorage.getItem("authUser"));
        const authUserType = JSON.parse(
          window.localStorage.getItem("authUserType")
        );
        const project = JSON.parse(
          window.localStorage.getItem("activeProject")
        );

        if (userObj && authUserType && project) {
          this.$store.dispatch("usersStore/setActiveProject", project);

          this.$store.dispatch("usersStore/setAuthUser", userObj);
          this.$store.dispatch("usersStore/setAuthUserType", authUserType);
          this.$store.dispatch("usersStore/setActiveUserType", authUserType);

          /*Active user */
          const activeUser = JSON.parse(
            window.localStorage.getItem("activeUser")
          );
          const activeUserType = JSON.parse(
            window.localStorage.getItem("activeUserType")
          );
          if (activeUser) {
            this.$store.dispatch("usersStore/setActiveUser", activeUser);
            this.$store.dispatch(
              "usersStore/setActiveUserType",
              activeUserType
            );
          } else {
            window.localStorage.setItem("activeUser", JSON.stringify(userObj));
            window.localStorage.setItem(
              "activeUserType",
              JSON.stringify(authUserType)
            );
            this.$store.dispatch("usersStore/setActiveUser", userObj);
            this.$store.dispatch("usersStore/setActiveUserType", authUserType);
          }

          /* Grab app mode from local storage */
          const appMode = JSON.parse(window.localStorage.getItem("appMode"));
          if (appMode == null) {
            let appMode;
            if (userObj.flag_admin === 1) {
              appMode = 2;
            } else {
              appMode = 1;
            }
            window.localStorage.setItem("appMode", appMode);
          }

          const adminMode = JSON.parse(
            window.localStorage.getItem("adminMode")
          );
          this.$store.dispatch("usersStore/setAdminMode", adminMode);

          this.switchAppModes(appMode);
        } else if (this.$route.name === "passwordreset") {
        } else {
          this.handleLogout();
        }
      }
    },

    async switchAppModes(mode) {
      this.$store.dispatch("progressStore/set", true);
      /* Store authorized user in store */
      this.$store.dispatch("usersStore/setAppMode", mode);
      if (mode === 2) {
        this.$store.dispatch("navigationStore/clearNavigation");

        await this.$store.dispatch("navigationStore/fetchAdmin");

        window.localStorage.setItem("appMode", 2);

        this.$router.push({ name: "settingsProjects" });

        //await this.$store.dispatch('navigationStore/fetchAdmin');
      } else {
        this.$store.dispatch("navigationStore/clearNavigationAdmin");

        window.localStorage.removeItem("appMode");
        this.$router.push({ name: "dashboard" });
      }
      this.$store.dispatch("progressStore/set", false);
    },

    handleLogout() {
      /* Send user to index */
      this.$router.push({ name: "logout" });
    },

    closeHelp() {
      this.helpDialog = false;
    },

    openRoadmap() {
      this.$router.push({ name: "roadmap" });
    },

    openChangePassword(id_user) {
      let data = {
        id_user: id_user,
      };
      this.$store.dispatch("usersStore/openPassword", data);
    },

    switchTheme() {
      if (this.$vuetify.theme.dark === false) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },

    async stopAdminMode() {
      this.$store.dispatch("progressStore/set", true);

      this.$store.dispatch("navigationStore/clearNavigation");

      let user = this.$store.getters["usersStore/getAuthUser"];
      let userType = this.$store.getters["usersStore/getAuthUserType"];
      if (!userType) {
        userType = JSON.parse(window.localStorage.getItem("authUserType"));
      }

      /* Store active user */
      window.localStorage.setItem("activeUser", JSON.stringify(user));
      this.$store.dispatch("usersStore/setActiveUser", user);
      let project = this.$store.getters["usersStore/getActiveProject"];

      /* Store active user type*/
      window.localStorage.setItem("activeUserType", JSON.stringify(userType));
      this.$store.dispatch("usersStore/setActiveUserType", userType);

      let data = {
        user_type: userType,
      };
      await this.$store.dispatch("navigationStore/fetch", data);

      window.localStorage.setItem("adminMode", 0);
      this.$store.dispatch("usersStore/setAdminMode", 0);

      if (
        userType === 4 ||
        userType === 5 ||
        userType === 6 ||
        userType === 7
      ) {
        /* Redirect user to dashboard */
        this.$router.push({ name: "administrator" });
      } else {
        /* Redirect user to dashboard */
        this.$router.push({ name: "dashboard" });
      }

      this.$store.dispatch("progressStore/set", false);
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
