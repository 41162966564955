import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  valueLists: [],
  valueListsCategories: [],
  valueListItems: [],
  days: [
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
  ],
  yearsNum: [
    1,2,3,4,5,6,7,8,9,10
  ],
  monthsNum: [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
    {
      id: 9,
    },
    {
      id: 10,
    },
    {
      id: 11,
    }
  ],
  months: [
    {
      id: 1,
      value: 'January',
    },
    {
      id: 2,
      value: 'February',
    },
    {
      id: 3,
      value: 'March',
    },
    {
      id: 4,
      value: 'April',
    },
    {
      id: 5,
      value: 'May',
    },
    {
      id: 6,
      value: 'June',
    },
    {
      id: 7,
      value: 'July',
    },
    {
      id: 8,
      value: 'August',
    },
    {
      id: 9,
      value: 'September',
    },
    {
      id: 10,
      value: 'October',
    },
    {
      id: 11,
      value: 'November',
    },
    {
      id: 12,
      value: 'December',
    },
  ],
  monthAbbreviations: [
    {
      id: 1,
      value: 'Jan',
    },
    {
      id: 2,
      value: 'Feb',
    },
    {
      id: 3,
      value: 'Mar',
    },
    {
      id: 4,
      value: 'Apr',
    },
    {
      id: 5,
      value: 'May',
    },
    {
      id: 6,
      value: 'Jun',
    },
    {
      id: 7,
      value: 'Jul',
    },
    {
      id: 8,
      value: 'Aug',
    },
    {
      id: 9,
      value: 'Sep',
    },
    {
      id: 10,
      value: 'Oct',
    },
    {
      id: 11,
      value: 'Nov',
    },
    {
      id: 12,
      value: 'Dec',
    },
  ],
  activeStatus: [
    {
      id: 1,
      value: 'Active',
    },
    {
      id: 0,
      value: 'Inactive',
    },
  ],
  accessStatus: [
    {
      id: 1,
      value: 'Has Access',
    },
    {
      id: 0,
      value: 'No Access',
    },
  ],
  emailStatus: [
    {
      id: 1,
      value: 'Pending',
    },
    {
      id: 0,
      value: 'Canceled',
    },
    {
      id: 2,
      value: 'Sent',
    },
  ],
  emailMode: [
    {
      id: 1,
      value: 'Live',
    },
    {
      id: 0,
      value: 'Test',
    },
  ],
  yesNo: [
    {
      id: 1,
      value: 'Yes',
    },
    {
      id: 0,
      value: 'No',
    },
  ],
  yesNoPartial: [
    {
      id: 1,
      value: 'Yes',
    },
    {
      id: 0,
      value: 'No',
    },
    {
      id: 2,
      value: 'Partial',
    },
  ],
  titles: [ 
    {
      value:'Assistant Professor'
    },
    {
      value: 'Associate Professor'
    },
    {
      value: 'Professor'
    },
    {
      value: 'Other'
    }
  ],
  classificationsResearchers: [
    {
      value: 'Early-Career Researcher'
    },
    {
      value: 'Senior Researcher'
    }
  ],
  classificationsTrainees: [
    {
      value: 'Postdoc'
    },
    {
      value: 'Graduate Student'
    },
    {
      value: 'Undergraduate'
    },
    {
      value: 'Other'
    }
  ],
  classificationsRppr: [
    {
      value: 'Co-investigator'
    },
    {
      value: 'Faculty'
    },
    {
      value: 'Community College Faculty'
    },
    {
      value: 'Technical School Faculty',
    },
    {
      value: 'K-12 Teacher',
    },
    {
      value: 'Postdoctoral (scholar, fellow or other postdoctoral position)',
    },
    {
      value: 'Other Professional',
    },
    {
      value: 'Technician',
    },
    {
      value: 'Staff Scientist (doctoral level)',
    },
    {
      value: 'Statistician',
    },
    {
      value: 'Graduate student (research assistant)',
    },
    {
      value: 'Non-student research assistant',
    },
    {
      value: 'Undergraduate Student',
    },
    {
      value: 'Technical School Student',
    },
    {
      value: 'High School Student',
    },
    {
      value: 'Consultant',
    },
    {
      value: 'Research Experience for Undergraduates (REU) Participant',
    },
    {
      value: 'Other'
    }
  ],
  gender: [
    {
      value: 'Female'
    },
    {
      value: 'Male'
    },
    {
      value: 'Prefer not to disclose'
    }
  ],
  race: [
    {
      value: 'Black or African American'
    },
    {
      value: 'Alaska Native'
    },
    {
      value: 'Asian'
    },
    {
      value: 'Native American'
    },
    {
      value: 'Native Hawaiian/Pacific Islander'
    },
    {
      value: 'White'
    },
    {
      value: 'Prefer not to disclose'
    }
  ],
  ethnicity: [
    {
      value: 'Hispanic or Latino/a'
    },
    {
      value: 'Not Hispanic or Latino/a'
    },
    {
      value: 'Prefer not to disclose'
    }
  ],
  disability: [
    {
      value: 'Have a disability'
    },
    {
      value: 'Do not have a disability'
    },
    {
      value: 'Prefer not to disclose'
    }
  ],
  degree : [
    {
      value: 'Ph.D.'
    },
    {
      value: 'M.S.'
    },
    {
      value: 'B.S.'
    },
    {
      value: 'B.A.'
    },
    {
      value: 'Other'
    }
  ],
  userTypes : [
    {
      id: 1,
      value: 'Principal Investigator'
    },
    {
      id: 2,
      value: 'Researcher'
    },
    {
      id: 3,
      value: 'Trainee'
    },
    {
      id: 4,
      value: 'Evaluator'
    },
    {
      id: 5,
      value: 'Coordinator'
    },
    {
      id: 6,
      value: 'NSF EPSCoR'
    },
    {
      id: 7,
      value: 'Program Officer'
    },
    {
      id: 8,
      value: 'RPPR'
    },
  ],
  userTypesOther : [
    {
      id: 4,
      value: 'Evaluator'
    },
    {
      id: 5,
      value: 'Coordinator'
    },
    {
      id: 6,
      value: 'NSF EPSCoR'
    },
    {
      id: 7,
      value: 'Program Officer'
    }
  ],
  faqTypes : [
    {
      id: 1,
      value: 'FAQ'
    },
    {
      id: 2,
      value: 'Link'
    },
    {
      id: 3,
      value: 'Post'
    },
    {
      id: 4,
      value: 'Header'
    }
  ],
  rpprOrganizationType : [
    {
      id: 1,
      value: 'Academic Institution'
    },
    {
      id: 2,
      value: 'Other Nonprofits'
    },
    {
      id: 3,
      value: 'Industrial or Commercial Firms'
    },
    {
      id: 4,
      value: 'State or Local Government'
    },
    {
      id: 5,
      value: 'School or School Systems'
    },
    {
      id: 6,
      value: 'Other Organizations (foreign or domestic)'
    }
  ],
  rpprOrganizationContribution : [
    {
      id: 1,
      value: 'Financial support'
    },
    {
      id: 2,
      value: 'In-kind support (e.g., partner makes software, computers, equipment, etc. available to project staff)'
    },
    {
      id: 3,
      value: 'Facilities (e.g., project staff use the partner\'s facilities for project activities)'
    },
    {
      id: 4,
      value: 'Collaborative research (e.g., partner\'s staff work with project staff on the project)'
    },
    {
      id: 5,
      value: 'Personnel exchanges (e.g., project staff and/or partner\'s staff use each other\'s facilities, work at each other\'s site)'
    },
    {
      id: 6,
      value: 'Other'
    },
  ],
  rpprSchooling: [
    {
      value: 'Freshman'
    },
    {
      value: 'Sophomore'
    },
    {
      value: 'Junior'
    },
    {
      value: 'Pre-College Teacher'
    },
    {
      value: 'Other'
    }
  ],
  rpprAppointmentTerm: [
    {
      value: '12 Months'
    },
    {
      value: '9 Months'
    },
    {
      value: '3 Months'
    },
    {
      value: 'Other'
    }
  ],
  rpprYear: [
    {
      id: 1,
      value: 'Y1'
    },
    {
      id: 2,
      value: 'Y2'
    },
    {
      id: 3,
      value: 'Y3'
    },
    {
      id: 4,
      value: 'Y4'
    },
    {
      id: 5,
      value: 'NCE1'
    },
    {
      id: 6,
      value: 'NCE2'
    }
  ],
  roleValueList: [
    { role: 1, name: 'PI or Co-PI' },
    { role: 2, name: 'Key Personnel' }
  ],
  positionList: [
    'Faculty or Equivalent',
    'Graduate Student',
    'Postdoc',
    'Other'
  ],
  institutionTypeList: [
    'College or University',
    'Federal laboratory',
    'Non-profit laboratory',
    'Industry',
    'Other'
  ],
  institutionLocationList: [
    'My T-2 award jurisdiction',
    'Other EPSCoR jurisdiction',
    'Other US',
    'International'
  ],
  fundingStatusValueList: [
    { status: 1, name: 'Submitted and Pending' },
    { status: 2, name: 'Awarded' },
    { status: 0, name: 'Not Awarded' },
  ],
  fundingTypeList: [
    'Grant',
    'Contract',
    'Cooperative Agreement',
    'Other'
  ],
  fundingOrganizationList: [
    'NSF EPSCoR',
    'Other NSF',
    'Other Federal',
    'State/Local',
    'Corporation',
    'Foundation',
    'Institution',
    'Other'
  ],
  patentStatusValueList: [
    { status: 1, name: 'Submitted' },
    { status: 3, name: 'Pending' },
    { status: 2, name: 'Granted' },
    { status: 0, name: 'Not Granted' },
  ],
  outcomeTypeList: [
    'Invention',
    'License',
    'Technologies and Techniques',
    'Website',
    'Other Product'
  ],
  outcomeStatusList:[
    {status: 1, name: 'Pending'},
    {status: 2, name: 'Licensed'},
    {status: 3, name: 'None'}
  ],
  outcomeProductTypeList: [
    'Audio or Video Products',
    'Data and Research Materials',
    'Databases',
    'Educational Aids or Curricula',
    'Evaluation Instruments',
    'Instruments or Equipment',
    'Models',
    'Physical Collections',
    'Protocols',
    'Software or Netware',
    'Survey Instruments',
    'Other'
  ],
  presentationStatusValueList: [
    { status: 5, name: 'Submitted' },
    { status: 4, name: 'Under Review' },
    { status: 3, name: 'Accepted' },
    { status: 2, name: 'Awaiting Publication' },
    { status: 1, name: 'Published' },
    { status: 6, name: 'Other' },
  ],
  presentationTypeList: [
    'Conference Talk',
    'Conference Poster',
    'Other'
  ],
  presentationLocationList: [
    'Within T-2 partner jurisdictions',
    'Domestic: outside T-2 partner jurisdictions',
    'International',
    'Online',
  ],
  fellowshipTypeList: [
    'Pre-Doctoral Fellowship',
    'Post-Doctoral Fellowship',
    'Other'
  ],
  fellowshipOrganizationList: [
    'NSF EPSCoR',
    'Other NSF',
    'Other Federal',
    'State/Local',
    'Corporation',
    'Foundation',
    'Institution',
    'Other'
  ],
  fellowshipStatusValueList: [
    { status: 1, name: 'Submitted and Pending' },
    { status: 2, name: 'Awarded' },
    { status: 0, name: 'Not Awarded' },
  ],
  dissertationTypeList: [
    'Undergraduate Thesis',
    'Masters Thesis',
    'Ph.D. Dissertation'
  ],
  publicationStatusValueList: [
    { status: 5, name: 'Submitted' },
    { status: 4, name: 'Under Review' },
    { status: 3, name: 'Accepted' },
    { status: 2, name: 'Awaiting Publication' },
    { status: 1, name: 'Published' },
    { status: 6, name: 'Other' },
  ],
  publicationTypeList: [
    'Journal Article Print',
    'Journal Article Electronic Only',
    'Conference Proceeding',
    'Book Chapter',
    'Book'
  ],
  publicationIdentificationType: [
    'DOI',
    'ISBN',
    'ISSN',
    'Other'
  ],
  publicationLocationList: [
    'Within T-2 partner jurisdictions',
    'Domestic: outside T-2 partner jurisdictions',
    'International',
    'Online',
  ],
  projectYearList: [],
  entry: {
    show: '',
    valid: '',
    mode: '',
    id: '',
    fk_valueListID: '',
    d_name: '',
    d_sort: '',
    flag_status: '',
  },
  filters: {
    d_name: '',
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.valueLists = [];
    state.valueListsCategories = [];
    state.valueListItems = [];
    state.filters = {};
  },
  FETCH(state, { list }) {
    state.valueLists = list;
  },
  CREATE(state, { data }) {
    const idx = state.valueLists.data.map(item => item.id).indexOf(data.fk_valueListID);
    state.valueLists.data[idx].items.push(data);
  },
  CREATE_LIST(state, { data }) {
    state.valueLists.data.push(data);
  },
  READ(state, { data }) {
    state.valueList = data;
  },
  UPDATE(state, { data }) {
    const idx = state.valueLists.data.map(item => item.id).indexOf(data.fk_valueListID);
    const idx2 = state.valueLists.data[idx].items.map(item => item.id).indexOf(data.id);
    state.valueLists.data[idx].items.splice(idx2, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.valueLists.data.map((item) => item.id).indexOf(id);
    state.valueLists.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  CATEGORY(state, { list }) {
    state.valueListsCategories = list;
  },
  FETCH_ITEMS(state, { list }) {
    state.valueListItems = list;
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.fk_valueListID = data.fk_valueListID;
    state.entry.d_name = data.d_name;
    state.entry.d_sort = data.d_sort;
    state.entry.flag_status = data.flag_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.fk_valueListID = '';
    state.entry.d_name = '';
    state.entry.d_sort = '';
    state.entry.flag_status = 1;
  },
  SET_PROJECT_YEAR_LIST(state, { data }) {
    state.projectYearList = [];
    let yearStart = data.yearStart
    let value = {
      value:yearStart
    }
    state.projectYearList.push(value)
    for(var i = 1;i <= 10;i++){
      yearStart++
      value = {
        value:yearStart
      }
      state.projectYearList.push(value)
    }
  },
  RESET_PROJECT_YEAR_LIST(state) {
    state.valueLists = [];
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/valuelists', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/valuelists/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async createList({ commit }, data) {
    try {
      const response = await axios.post('/api/valuelists/create', data, { headers: getHeader() });
      commit('CREATE_LIST', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/valuelists/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/valuelists/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/valuelists/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/valuelists/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async categories({ commit }) {
    try {
      const response = await axios.get('/api/valuelistscategories', { headers: getHeader() });
      commit('CATEGORY', { list: response.data });
    } catch (error) {

    }
  },
  async items({ commit }, data) {
    try {
      const response = await axios.post('/api/valuelists/items', data, { headers: getHeader() });
      commit('FETCH_ITEMS', { list: response.data });
    } catch (error) {

    }
  },
  async setProjectYearList({ commit } , data) {
    commit('SET_PROJECT_YEAR_LIST', { data: data });
  },
  resetProjectYearList({ commit }) {
    commit('RESET_PROJECT_YEAR_LIST');
  },
  resetEntry({ commit }) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getValueLists: (state, getters) => state.valueLists,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
