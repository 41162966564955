<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="6" md="4" lg="3" xl="3">
      <v-card flat>
        <v-row class="pa-4">
          <v-img
            v-if="$vuetify.theme.dark === false"
            contain
            height="150px"
            :src="require('@/assets/logo_login_light.png')"
          />
          <v-img
            v-if="$vuetify.theme.dark === true"
            contain
            height="150px"
            :src="require('@/assets/logo_login_dark.png')"
          />
        </v-row>
        <p
          class="text-h6 text-center"
          :class="
            $vuetify.theme.dark === false ? 'primary--text' : 'secondary--text'
          "
        >
          Data Outcomes Portal (DOP)
        </p>
        <v-card-text>
          <v-form @submit.prevent="onLogin">
            <v-text-field
              v-model="email"
              prepend-icon="fal fa-user-circle"
              label="E-Mail Address"
              placeholder=" "
              type="text"
            />
            <v-text-field
              id="password"
              v-model="password"
              prepend-icon="fal fa-lock"
              label="Password"
              placeholder=" "
              type="password"
            />
            <v-btn rounded color="primary" block type="submit">
              Login
            </v-btn>
          </v-form>

          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="12" class="text-sm-center">
                <forgot-password />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { clientID, clientSecret } from "@/env";
import ForgotPassword from "@/components/ForgotPassword";

export default {
  name: "Login",
  components: {
    ForgotPassword,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      messagesStore: (state) => state.messagesStore,
      navigationStore: (state) => state.navigationStore,
    }),
  },
  methods: {
    /* ---------- Login ----------*/
    onLogin() {
      /* Build initial data for token request */
      const data = {
        client_id: clientID,
        client_secret: clientSecret,
        grant_type: "password",
        username: this.email,
        password: this.password,
      };
      const authUser = {};
      /* POST user data to Auth API */
      // eslint-disable-next-line
      axios
        /* POST to OAuth service to obtain token */
        .post("/oauth/token", data)
        // eslint-disable-next-line
        .then((response) => {
          /* Grab response tokens and put the info in local storage */
          authUser.access_token = response.data.access_token;
          authUser.refresh_token = response.data.refresh_token;
          authUser.expires_in = response.data.expires_in;
          const headers = {
            Accept: "application/json",
            Authorization: `Bearer ${authUser.access_token}`,
          };
          /* GET User */
          // eslint-disable-next-line
          axios
            .get("/api/user", { headers })
            // eslint-disable-next-line
            .then((response) => {
              if (response.data.flag_status === 1) {
                /* push auth tokens into user json */
                response.data.access_token = authUser.access_token;
                response.data.refresh_token = authUser.refresh_token;
                response.data.expires_in = authUser.expires_in;

                /* Store auth user */
                window.localStorage.setItem(
                  "authUser",
                  JSON.stringify(response.data)
                );
                /* Store active user */
                window.localStorage.setItem(
                  "activeUser",
                  JSON.stringify(response.data)
                );

                /* Store authorized user in store */
                this.$store.dispatch("usersStore/setAuthUser", response.data);
                this.$store.dispatch("usersStore/setActiveUser", response.data);

                /*Admin users */
                if (response.data.flag_admin === 1) {
                  this.$store.dispatch("usersStore/setAppMode", 2);
                  this.$store.dispatch("navigationStore/fetchAdmin");

                  window.localStorage.setItem("appMode", 2);

                  this.$router.push({ name: "settingsProjects" });
                } else {
                  /*All other users */
                  this.$store.dispatch("usersStore/setAppMode", 1);
                  /*Record last login to the system */
                  let login = {
                    id_user: response.data.id,
                  };
                  axios.post("api/users/logins/last", login, { headers });

                  const authUser = this.$store.getters[
                    "usersStore/getAuthUser"
                  ];

                  // if (authUser.flag_type === null)
                  //   this.$router.push({ name: "logout" });
                  // else {
                  /* Redirect user to dashboard */
                  this.$router.push({ name: "dashboard" });
                  // }
                }
              } else {
                /* Show login error */
                this.openSnackbar(1);
              }
            })
            .catch((error) => {
              /* Show login error */
              this.openSnackbar(2);
            });
        })
        .catch((error) => {
          /* Show login error */
          this.openSnackbar(3);
        });
    },

    openSnackbar(code) {
      let data;
      if (code === 1) {
        data = {
          color: "error",
          text: "Login error - access has expired. Please contact support.",
        };
      } else if (code === 2) {
        data = {
          color: "error",
          text: "Login error - Please contact support.",
        };
      } else if (code === 3) {
        data = {
          color: "error",
          text: "Login error - email and password combination are incorrect.",
        };
      } else if (code === 4) {
        data = {
          color: "success",
          text: "Password reset email sent successfully.",
        };
      } else if (code === 5) {
        data = {
          color: "error",
          text:
            "There was an error with the request. Please contact support if the issue continues.",
        };
      }
      this.$store.commit("messagesStore/setMessage", data);
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
