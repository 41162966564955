import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';
import Axios from 'axios';
import { read } from 'fs';

const state = {
  baseline: {},
  baselines: [],
  export: [],

  entry: {
    show: false,
    valid: '',
    mode: '',
    tab: '',
    id: '',

    id_user: '',
    id_project: '',
    pubsTotal: '',
    pubsT2: '',
    pubsCollab: '',
    pubsCollabT2: '',
    pubsIntra: '',
    pubsInter: '',
    proposalsTotalAllSub: '',
    proposalsTotalAllFund: '',
    proposalsTotalAllPend: '',
    proposalsTotalNSFSub: '',
    proposalsTotalNSFFund: '',
    proposalsTotalNSFPend: '',
    proposalsT2AllSub: '',
    proposalsT2AllFund: '',
    proposalsT2AllPend: '',
    proposalsT2NSFSub: '',
    proposalsT2NSFFund: '',
    proposalsT2NSFPend: '',
    proposalsCollabAllSub: '',
    proposalsCollabAllFund: '',
    proposalsCollabAllPend: '',
    proposalsCollabNSFSub: '',
    proposalsCollabNSFFund: '',
    proposalsCollabNSFPend: '',
    proposalsCollabT2AllSub: '',
    proposalsCollabT2AllFund: '',
    proposalsCollabT2AllPend: '',
    proposalsCollabT2NSFSub: '',
    proposalsCollabT2NSFFund: '',
    proposalsCollabT2NSFPend: '',
    proposalsIntraAllSub: '',
    proposalsIntraAllFund: '',
    proposalsIntraAllPend: '',
    proposalsIntraNSFSub: '',
    proposalsIntraNSFFund: '',
    proposalsIntraNSFPend: '',
    proposalsInterAllSub: '',
    proposalsInterAllFund: '',
    proposalsInterAllPend: '',
    proposalsInterNSFSub: '',
    proposalsInterNSFFund: '',
    proposalsInterNSFPend: '',
  },
  filters: {
    page: '',

    // FILTER FIELDS
    id_user: '',
    id_project: '',
    title: '',

  }
};

const mutations = {
  CLEAR_STORE(state) {
    state.baselines = [],
    state.baseline = {},
  
    state.entry = {
      show: false,
      valid: '',
      mode: '',
      tab: '',
      id: '',
  
      id_user: '',
      id_project: '',
      pubsTotal: '',
      pubsT2: '',
      pubsCollab: '',
      pubsCollabT2: '',
      pubsIntra: '',
      pubsInter: '',
      proposalsTotalAllSub: '',
      proposalsTotalAllFund: '',
      proposalsTotalAllPend: '',
      proposalsTotalNSFSub: '',
      proposalsTotalNSFFund: '',
      proposalsTotalNSFPend: '',
      proposalsT2AllSub: '',
      proposalsT2AllFund: '',
      proposalsT2AllPend: '',
      proposalsT2NSFSub: '',
      proposalsT2NSFFund: '',
      proposalsT2NSFPend: '',
      proposalsCollabAllSub: '',
      proposalsCollabAllFund: '',
      proposalsCollabAllPend: '',
      proposalsCollabNSFSub: '',
      proposalsCollabNSFFund: '',
      proposalsCollabNSFPend: '',
      proposalsCollabT2AllSub: '',
      proposalsCollabT2AllFund: '',
      proposalsCollabT2AllPend: '',
      proposalsCollabT2NSFSub: '',
      proposalsCollabT2NSFFund: '',
      proposalsCollabT2NSFPend: '',
      proposalsIntraAllSub: '',
      proposalsIntraAllFund: '',
      proposalsIntraAllPend: '',
      proposalsIntraNSFSub: '',
      proposalsIntraNSFFund: '',
      proposalsIntraNSFPend: '',
      proposalsInterAllSub: '',
      proposalsInterAllFund: '',
      proposalsInterAllPend: '',
      proposalsInterNSFSub: '',
      proposalsInterNSFFund: '',
      proposalsInterNSFPend: '',
    },
    state.filters = {
      page: '',
  
      // FILTER FIELDS
      title: '',
  
    }
  },
  FETCH(state, { list }) {
    state.baselines = list;
  },
  CREATE(state, { data }) {
    state.baseline = data;
  },
  READ(state, { data }) {
    state.baseline = data;
  },
  UPDATE(state, { data }) {
    state.baseline = data;
  },
  DELETE(state, { id }) {
    const index = state.baselines.data.map(item => item.id).indexOf(id);
    state.baselines.data.splice(index, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.tab = 0;
    state.entry.id = data.id;

    state.entry.id_user = data.id_user;
    state.entry.id_project = data.id_project;
    state.entry.pubsTotal = data.pubsTotal;
    state.entry.pubsT2 = data.pubsT2;
    state.entry.pubsCollab = data.pubsCollab;
    state.entry.pubsCollabT2 = data.pubsCollabT2;
    state.entry.pubsIntra = data.pubsIntra;
    state.entry.pubsInter = data.pubsInter;
    state.entry.proposalsTotalAllSub = data.proposalsTotalAllSub;
    state.entry.proposalsTotalAllFund = data.proposalsTotalAllFund;
    state.entry.proposalsTotalAllPend = data.proposalsTotalAllPend;
    state.entry.proposalsTotalNSFSub = data.proposalsTotalNSFSub;
    state.entry.proposalsTotalNSFFund = data.proposalsTotalNSFFund;
    state.entry.proposalsTotalNSFPend = data.proposalsTotalNSFPend;
    state.entry.proposalsT2AllSub = data.proposalsT2AllSub;
    state.entry.proposalsT2AllFund = data.proposalsT2AllFund;
    state.entry.proposalsT2AllPend = data.proposalsT2AllPend;
    state.entry.proposalsT2NSFSub = data.proposalsT2NSFSub;
    state.entry.proposalsT2NSFFund = data.proposalsT2NSFFund;
    state.entry.proposalsT2NSFPend = data.proposalsT2NSFPend;
    state.entry.proposalsCollabAllSub = data.proposalsCollabAllSub;
    state.entry.proposalsCollabAllFund = data.proposalsCollabAllFund;
    state.entry.proposalsCollabAllPend = data.proposalsCollabAllPend;
    state.entry.proposalsCollabNSFSub = data.proposalsCollabNSFSub;
    state.entry.proposalsCollabNSFFund = data.proposalsCollabNSFFund;
    state.entry.proposalsCollabNSFPend = data.proposalsCollabNSFPend;
    state.entry.proposalsCollabT2AllSub = data.proposalsCollabT2AllSub;
    state.entry.proposalsCollabT2AllFund = data.proposalsCollabT2AllFund;
    state.entry.proposalsCollabT2AllPend = data.proposalsCollabT2AllPend;
    state.entry.proposalsCollabT2NSFSub = data.proposalsCollabT2NSFSub;
    state.entry.proposalsCollabT2NSFFund = data.proposalsCollabT2NSFFund;
    state.entry.proposalsCollabT2NSFPend = data.proposalsCollabT2NSFPend;
    state.entry.proposalsIntraAllSub = data.proposalsIntraAllSub;
    state.entry.proposalsIntraAllFund = data.proposalsIntraAllFund;
    state.entry.proposalsIntraAllPend = data.proposalsIntraAllPend;
    state.entry.proposalsIntraNSFSub = data.proposalsIntraNSFSub;
    state.entry.proposalsIntraNSFFund = data.proposalsIntraNSFFund;
    state.entry.proposalsIntraNSFPend = data.proposalsIntraNSFPend;
    state.entry.proposalsInterAllSub = data.proposalsInterAllSub;
    state.entry.proposalsInterAllFund = data.proposalsInterAllFund;
    state.entry.proposalsInterAllPend = data.proposalsInterAllPend;
    state.entry.proposalsInterNSFSub = data.proposalsInterNSFSub;
    state.entry.proposalsInterNSFFund = data.proposalsInterNSFFund;
    state.entry.proposalsInterNSFPend = data.proposalsInterNSFPend;

    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.tab = 0;
    state.entry.id = '';

    state.entry.id_user = '';
    state.entry.id_project = '';
    state.entry.pubsTotal = '';
    state.entry.pubsT2 = '';
    state.entry.pubsCollab = '';
    state.entry.pubsCollabT2 = '';
    state.entry.pubsIntra = '';
    state.entry.pubsInter = '';
    state.entry.proposalsTotalAllSub = '';
    state.entry.proposalsTotalAllFund = '';
    state.entry.proposalsTotalAllPend = '';
    state.entry.proposalsTotalNSFSub = '';
    state.entry.proposalsTotalNSFFund = '';
    state.entry.proposalsTotalNSFPend = '';
    state.entry.proposalsT2AllSub = '';
    state.entry.proposalsT2AllFund = '';
    state.entry.proposalsT2AllPend = '';
    state.entry.proposalsT2NSFSub = '';
    state.entry.proposalsT2NSFFund = '';
    state.entry.proposalsT2NSFPend = '';
    state.entry.proposalsCollabAllSub = '';
    state.entry.proposalsCollabAllFund = '';
    state.entry.proposalsCollabAllPend = '';
    state.entry.proposalsCollabNSFSub = '';
    state.entry.proposalsCollabNSFFund = '';
    state.entry.proposalsCollabNSFPend = '';
    state.entry.proposalsCollabT2AllSub = '';
    state.entry.proposalsCollabT2AllFund = '';
    state.entry.proposalsCollabT2AllPend = '';
    state.entry.proposalsCollabT2NSFSub = '';
    state.entry.proposalsCollabT2NSFFund = '';
    state.entry.proposalsCollabT2NSFPend = '';
    state.entry.proposalsIntraAllSub = '';
    state.entry.proposalsIntraAllFund = '';
    state.entry.proposalsIntraAllPend = '';
    state.entry.proposalsIntraNSFSub = '';
    state.entry.proposalsIntraNSFFund = '';
    state.entry.proposalsIntraNSFPend = '';
    state.entry.proposalsInterAllSub = '';
    state.entry.proposalsInterAllFund = '';
    state.entry.proposalsInterAllPend = '';
    state.entry.proposalsInterNSFSub = '';
    state.entry.proposalsInterNSFFund = '';
    state.entry.proposalsInterNSFPend = '';
  },
  VALUE_LIST(state, { list }) {
    state.baselinesValueList = list;
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/baselines', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (e) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/baselines/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (e) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/baselines/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (e) {

    }
  },
  async myBaseline({ commit}, data) {
    try {
      const response = await axios.post('/api/baselines/mybaseline', data, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (e) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/baselines/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (e) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/baselines/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post('/api/baselines', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data});
    } catch (error) {

    }
  },
};

const getters = {
  getBaseline: (state, getters) => state.baseline,
  getBaselines: (state, getters) => state.baselines,
  getEntry: (state, getters) => state.entry,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};