import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  userProfile: {},
  user: {},
  users: [],
  researchers: [],
  trainees: [],
  otherUsers: [],
  rppr: [],
  usersValueList: [],
  export: [],
  authUser: null,
  authUserType:null,
  activeUser: null,
  activeUserType: null,
  activeProject:null,
  appMode: null,
  adminMode: '',
  
  entry: {
    show: false,
    valid: false,
    mode: '',
    id: '',

    sectionStored: '',
    
    name_first: '',
    name_last: '',
    emailAddress: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    flag_status: '',
    flag_admin: '',
    flag_hide: '',
    flag_show: '',
    emailCheck: '',

    id_participation: '',
    id_project: '',
    date_start_project: '',
    date_start_month: '',
    date_start_year: '',
    date_end_month: '',
    date_end_year: '',
    flag_contact_pi: '',
    flag_user_type: '',
    participation_flag_status: '',
    advisors: [],
    sendInvite: '',
    flag_email: '',
    classification: '',
    classification_other: ''
  },

  profile: {
    show: false,
    valid:false,
    mode: '',

    id: '',
    id_project: '',
    date_start_project: '',
    id_user: '',
    id_institution: '',
    id_department: '',
    id_jurisdiction: '',
    institution_other: '',
    department_other: '',
    orcid_id: '',
    title:'',
    title_other: '',
    classification: '',
    classification_other: '',
    gender: '',
    race: [],
    ethnicity: '',
    disability: '',
    phone: '',
    degree: '',
    degree_earned: '',
    degree_month: '',
    degree_year: '',
    jurisdiction: '',
    department: '',
    institution: '',
    flag_user_type: '',
    flag_check: '',
    date_profile_check: '',

    readonly: '',
    flag_status: '',
  },

  rpprEntry: {
    show: false,
    valid: false,
    mode: '',
    id: '',

    participation: [],

    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_project: '',
    date_start_project: '',
    id_user: '',
    id_year: '',
    year_schooling_completed: '',
    home_institution: '',
    gov_fiscal_year: [],
    percent_effort: '',
    appointment_term: '',
    appointment_term_other: '',
    funding_support: '',
    person_months: '',
    contribution: '',
    collab_internationally: '',
    collab_countries: [],
    travel_internationally: '',
    travel_countries: [{
      country: '',
      years: '',
      months: '',
      days: '',
    }],
    flag_status: '',
  },

  password: {
    show: false,
    valid: false,
    id_user: '',
    password: '',
    passwordConfirmation: '',
  },

  filters: {
    page: '',
    sectionStored: '',
    id: '',
    export: '',

    activeUser: '',
    
    id_project: '',
    name_first: '',
    name_last: '',
    email: '',
    flag_user_type: '',
    flag_contact_pi: '',
    flag_status: '',
  },

  researcherFilters: {
    page: '',
    sectionStored: '',
    export: '',

    activeUser: '',

    id_project: '',
    researcher_id: '',
    researcher_name_first: '',
    researcher_name_last: '',
    researcher_flag_user_type: '',
    email: '',
  },

  traineeFilters: {
    page: '',
    sectionStored: '',
    export: '',

    activeUser: '',

    trainee_id: '',
    trainee_name_first: '',
    trainee_name_last: '',
    trainee_flag_user_type: '',
    email: '',
  },

  otherUserFilters: {
    page: '',
    sectionStored: '',
    export: '',

    activeUser: '',

    other_user_id: '',
    other_user_name_first: '',
    other_user_name_last: '',
    other_user_flag_user_type: '',
    email: '',
  },

  rpprFilters: {
    page: '',
    sectionStored: '',
    export: '',

    activeUser: '',

    rppr_id: '',
    rppr_name_first: '',
    rppr_name_last: '',
    rppr_flag_user_type: '',
    email: '',
  }
};

const mutations = {
  CLEAR_STORE(state) {
    state.userProfile = {}
    state.user = {},
    state.users = [];
    state.export = [];
    state.authUser = null;
    state.authUserType = null;
    state.activeUser = null;
    state.activeUserType = null;
    state.activeProject = null;
    state.appMode = null;

    state.entry = {
      show: false,
      valid: false,
      mode: '',
      id: '',

      sectionStored: '',
      
      name_first: '',
      name_last: '',
      emailAddress: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      flag_status: '',
      flag_admin: '',
      flag_hide: '',
      flag_show: '',
      emailCheck: '',

      id_participation: '',
      id_project: '',
      date_start_project: '',
      date_start_month: '',
      date_start_year: '',
      date_end_month: '',
      date_end_year: '',
      flag_contact_pi: '',
      flag_user_type: '',
      participation_flag_status: '',
      advisors: [],
      sendInvite: '',
      flag_email: '',
      classification: '',
      classification_other: '',
    },

    state.filters = {
      page: '',
      sectionStored: '',
      id: '',
      export: '',

      activeUser: '',
      
      id_project: '',
      name_first: '',
      name_last: '',
      email: '',
      flag_user_type: '',
      flag_contact_pi: '',
      flag_status: '',
    }

    state.researcherFilters = {
      page: '',
      sectionStored: '',
      export: '',
  
      activeUser: '',
  
      id_project: '',
      researcher_id: '',
      researcher_name_first: '',
      researcher_name_last: '',
      researcher_flag_user_type: '',
      email: '',
    }

    state.traineeFilters = {
      page: '',
      sectionStored: '',
      export: '',
  
      activeUser: '',

      id_project: '',

      trainee_id: '',
      trainee_name_first: '',
      trainee_name_last: '',
      trainee_flag_user_type: '',
      email: '',
    }

    state.otherUserFilters = {
      page: '',
      sectionStored: '',
      export: '',
  
      activeUser: '',

      id_project: '',
  
      other_user_id: '',
      other_user_name_first: '',
      other_user_name_last: '',
      other_user_flag_user_type: '',
      email: '',
    }

    state.rpprFilters = {
      page: '',
      sectionStored: '',
      export: '',
  
      activeUser: '',
  
      rppr_id: '',
      rppr_name_first: '',
      rppr_name_last: '',
      rppr_flag_user_type: '',
      email: '',
    }

    state.rpprEntry = {
      show: false,
      valid: false,
      mode: '',
      id: '',

      participation: [],

      /*-----ADD ENTRY FIELDS BELOW-----*/
      id_project: '',
      date_start_project: '',
      id_user: '',
      id_year: '',
      year_schooling_completed: '',
      home_institution: '',
      gov_fiscal_year: [],
      percent_effort: '',
      appointment_term: '',
      appointment_term_other: '',
      funding_support: '',
      person_months: '',
      contribution: '',
      collab_internationally: '',
      collab_countries: [],
      travel_internationally: '',
      travel_countries: [{
        country: '',
        years: '',
        months: '',
        days: '',
      }],
      flag_status: '',
    }
  },
  CLEAR_USERS(state) {
    state.users = [];
  },
  CLEAR_RESEARCHERS(state) {
    state.researchers = [];
  },
  CLEAR_TRAINEES(state) {
    state.trainees = [];
  },
  CLEAR_OTHER_USERS(state) {
    state.otherUsers = [];
  },
  CLEAR_RPPR(state) {
    state.rppr = [];
  },
  CLEAR_ALL_USER_LISTS(state){
    state.users = [];
    state.researchers = [];
    state.trainees = [];
    state.otherUsers = []
    state.rppr = [];
    state.filters.sectionStored = '';
  },
  EXPORT_RESEARCHER_FILTERS(state, {data}) {
    state.researcherFilters.sectionStored = 5
    state.researcherFilters.export = 1
    state.researcherFilters.researcher_flag_user_type = [1,2];
    state.researcherFilters.id_project = data.id_project
    state.researcherFilters.researcher_id = '';
    state.researcherFilters.researcher_name_first = '';
    state.researcherFilters.researcher_name_last = '';
    state.researcherFilters.email = '';
  },
  EXPORT_TRAINEE_FILTERS(state, {data}) {
    state.traineeFilters.sectionStored = 6
    state.traineeFilters.export = 1
    state.traineeFilters.trainee_flag_user_type = 3;
    state.traineeFilters.id_project = data.id_project;
    state.traineeFilters.trainee_id = '';
    state.traineeFilters.trainee_name_first = '';
    state.traineeFilters.trainee_name_last = '';
    state.traineeFilters.email = '';
  },
  FETCH(state, { list }) {
    state.users = list;
  },
  FETCH_RESEARCHERS(state, { list }) {
    state.researchers = list;
  },
  FETCH_TRAINEES(state, { list }) {
    state.trainees = list;
  },
  FETCH_OTHER_USERS(state, { list }) {
    state.otherUsers = list;
  },
  FETCH_RPPR(state, { list }) {
    state.rppr = list;
  },

  CREATE(state, { data }) {
    state.user = data;
    state.users.data.unshift(data);
  },
  CREATE_RESEARCHERS(state, { data }) {
    state.user = data;
    state.researchers.data.unshift(data);
  },
  CREATE_TRAINEES(state, { data }) {
    state.user = data;
    state.trainees.data.unshift(data);
  },
  CREATE_OTHER_USERS(state, { data }) {
    state.user = data;
    state.otherUsers.data.unshift(data);
  },
  CREATE_RPPR(state, { data }) {
    state.rppr.data.unshift(data);
  },

  READ(state, { data }) {
    state.user = data;
  },
  READ_PROFILE(state, { data }) {
    state.userProfile = data;
  },

  UPDATE(state, { data }) {
    state.user = data;
    const idx = state.users.data.map(item => item.id).indexOf(data.id);
    state.users.data.splice(idx, 1, data);
  },
  UPDATE_RESEARCHERS(state, { data }) {
    const idx = state.researchers.data.map(item => item.id).indexOf(data.id);
    state.researchers.data.splice(idx, 1, data);
  },
  UPDATE_TRAINEES(state, { data }) {
    const idx = state.trainees.data.map(item => item.id).indexOf(data.id);
    state.trainees.data.splice(idx, 1, data);
  },
  UPDATE_OTHER_USERS(state, { data }) {
    const idx = state.otherUsers.data.map(item => item.id).indexOf(data.id);
    state.otherUsers.data.splice(idx, 1, data);
  },
  UPDATE_RPPR(state, { data }) {
    const idx = state.rppr.data.map(item => item.id).indexOf(data.id);
    state.rppr.data.splice(idx, 1, data);
  },

  DELETE(state, { id }) {
    const idx = state.users.data.map(item => item.id).indexOf(id);
    state.users.data.splice(idx, 1);
  },
  SET_AUTH_USER(state, userObj) {
    state.authUser = userObj;
  },
  SET_AUTH_USER_TYPE(state, id) {
    state.authUserType = id;
  },
  SET_ACTIVE_USER(state, userObj) {
    state.activeUser = userObj;
  },
  SET_ACTIVE_USER_TYPE(state, id) {
    state.activeUserType = id;
  },
  SET_ACTIVE_PROJECT(state, project) {
    state.activeProject = project;
  },
  CLEAR_ACTIVE_PROJECT(state) {
    state.activeProject = '';
  },
  CLEAR_ACTIVE_USER_TYPE(state) {
    state.activeUserType = '';
  },
  CLEAR_AUTH_USER_TYPE(state) {
    state.authUserType = '';
  },
  SET_APP_MODE(state, mode) {
    state.appMode = mode;
  },
  SET_ADMIN_MODE(state, mode) {
    state.adminMode = mode;
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
  UPDATE_PROFILE(state, data) {
    state.activeProject.pivot = data.data;
  },
  ENTRY(state, { data }) {
    state.entry.emailCheck = 1;
    state.entry.valid = true;
    state.entry.mode = 2;
    state.entry.id = data.id;

    state.entry.name_first = data.name_first;
    state.entry.name_last = data.name_last;
    state.entry.email = data.email;
    state.entry.emailAddress = '';
    state.entry.password = '';
    state.entry.passwordConfirmation = '';
    state.entry.flag_status = data.flag_status ? data.flag_status : 1;
    state.entry.flag_admin = data.flag_admin ? data.flag_admin : 0;
    state.entry.flag_hide = data.flag_hide ? data.flag_hide : 0;
    state.entry.flag_show = data.flag_show ? data.flag_show : 1;
    state.entry.id_participation = data.participation ? data.participation.id : '';
    state.entry.id_project = data.participation ? data.participation.id_project : '';
    state.entry.date_start_month = data.participation ? data.participation.date_start_month : '';
    state.entry.date_start_year = data.participation ? data.participation.date_start_year : '';
    state.entry.date_end_month = data.participation ? data.participation.date_end_month : '';
    state.entry.date_end_year = data.participation ? data.participation.date_end_year : '';
    state.entry.participation_flag_status = data.participation ? data.participation.flag_status: 1;
    state.entry.flag_contact_pi = data.participation ? data.participation.flag_contact_pi : '';
    state.entry.flag_user_type = data.participation ? data.participation.flag_user_type : '';
    state.entry.sendInvite = data.participation ? data.participation.sendInvite : '';
    state.entry.flag_email = data.participation ? data.participation.flag_email : '';
    state.entry.classification = data.participation ? data.participation.classification : '';
    state.entry.classification_other = data.participation ? data.participation.classification_other : '';
    state.entry.show = true;
  },
  SEARCH_EMAIL(state, { data }) {
    state.entry.emailCheck = 1;
    state.entry.valid = true;
    state.entry.mode = data.id ? 2 : 1;
    state.entry.id = data.id;
    
    state.entry.email = state.entry.emailAddress;
    state.entry.name_first = data.name_first;
    state.entry.name_last = data.name_last;
    state.entry.password = '';
    state.entry.passwordConfirmation = '';
    state.entry.flag_status = data.flag_status ? data.flag_status : 1;
    state.entry.flag_admin = data.flag_admin ? data.flag_admin : 0;
    state.entry.flag_hide = data.flag_hide ? data.flag_hide : 0;
    state.entry.flag_show = data.flag_show ? data.flag_show : 1;
    state.entry.id_participation = data.participation ? data.participation.id : '';
    state.entry.flag_email = data.flag_email ? data.flag_email : 1;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.emailCheck = '';
    state.entry.valid = true;
    state.entry.mode = 1;
    state.entry.id = '';

    state.entry.sectionStored = '';

    state.entry.name_first = '';
    state.entry.name_last = '';
    state.entry.email = '';
    state.entry.emailAddress = '';
    state.entry.password = '';
    state.entry.passwordConfirmation = '';
    state.entry.flag_status = 1;
    state.entry.flag_admin = 0;
    state.entry.flag_hide = 0;
    state.entry.flag_show = 1;

    state.entry.id_participation = '';
    state.entry.id_project = '';
    state.entry.date_start_project = '';
    state.entry.date_start_month = '';
    state.entry.date_start_year = '';
    state.entry.date_end_month = '';
    state.entry.date_end_year = '';
    state.entry.flag_contact_pi = '';
    state.entry.flag_user_type = '';
    state.entry.participation_flag_status = 1;
    state.entry.advisors = [];
    state.entry.sendInvite = '';
    state.entry.flag_email = '';
    state.entry.classification = '';
    state.entry.classification_other = '';
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  GET_PROFILE(state, { data }) {
    state.profile.valid = false;
    state.profile.id = data.id;
    state.profile.mode = 2
    state.profile.id_project = data.id_project
    state.profile.id_user = data.id_user
    state.profile.id_institution = data.id_institution
    state.profile.id_department = data.id_department
    state.profile.id_jurisdiction = data.id_jurisdiction
    state.profile.institution_other = data.institution_other
    state.profile.department_other = data.department_other
    state.profile.orcid_id = data.orcid_id
    state.profile.title = data.title
    state.profile.title_other = data.title_other
    state.profile.classification = data.classification
    state.profile.classification_other = data.classification_other
    state.profile.gender = data.gender
    state.profile.race = data.race
    state.profile.ethnicity = data.ethnicity
    state.profile.disability = data.disability
    state.profile.phone = data.phone
    state.profile.degree = data.degree
    state.profile.degree_earned = data.degree_earned
    state.profile.degree_month = data.degree_month
    state.profile.degree_year = data.degree_year
    state.profile.jurisdiction = data.jurisdiction
    state.profile.department = data.department
    state.profile.institution = data.institution
    state.profile.flag_user_type = data.flag_user_type
    state.profile.flag_check = data.flag_check
    state.profile.flag_status = data.flag_status
    state.profile.date_profile_check = data.date_profile_check
    state.profile.readonly = data.flag_status && data.flag_status === 1 ? false : true
  },
  RESET_PROFILE(state) {
    state.entry.valid = false;
    state.profile.id = '';
    state.profile.id_project = ''
    state.profile.id_user = ''
    state.profile.id_institution = ''
    state.profile.id_department = ''
    state.profile.id_jurisdiction = ''
    state.profile.institution_other = ''
    state.profile.department_other = ''
    state.profile.orcid_id = ''
    state.profile.title = ''
    state.profile.title_other = ''
    state.profile.classification = ''
    state.profile.classification_other = ''
    state.profile.gender = ''
    state.profile.race = []
    state.profile.ethnicity = ''
    state.profile.disability = ''
    state.profile.phone = ''
    state.profile.degree = ''
    state.profile.degree_earned = ''
    state.profile.degree_month = ''
    state.profile.degree_year = ''
    state.profile.jurisdiction = ''
    state.profile.department = ''
    state.profile.institution = ''
    state.profile.flag_user_type = ''
    state.profile.date_profile_check= ''
    state.profile.flag_check = ''
    
  },
  NEW_RPPR_ENTRY(state, { data }) {
    state.rpprEntry.mode = 1;
    state.rpprEntry.valid = true;
    state.rpprEntry.id_project = data.id_project;
    state.rpprEntry.id_user = data.id_user;
    state.rpprEntry.id_year = data.id_year;
    state.rpprEntry.show = true;
  },
  RPPR_ENTRY(state, { data}) {
    state.rpprEntry.mode = 2;
    state.rpprEntry.valid = true;
    state.rpprEntry.id = data.id;
    state.rpprEntry.id_project = data.id_project;
    state.rpprEntry.id_user = data.id_user;
    state.rpprEntry.id_year = data.id_year;
    state.rpprEntry.year_schooling_completed = data.year_schooling_completed;
    state.rpprEntry.home_institution = data.home_institution;
    state.rpprEntry.gov_fiscal_year = data.gov_fiscal_year;
    state.rpprEntry.percent_effort = data.percent_effort;
    state.rpprEntry.appointment_term = data.appointment_term;
    state.rpprEntry.appointment_term_other = data.appointment_term_other;
    state.rpprEntry.funding_support = data.funding_support;
    state.rpprEntry.person_months = data.person_months;
    state.rpprEntry.contribution = data.contribution;
    state.rpprEntry.collab_internationally = data.collab_internationally;
    state.rpprEntry.collab_countries = data.collab_countries;
    state.rpprEntry.travel_internationally = data.travel_internationally;
    state.rpprEntry.travel_countries = data.travel_countries;
    state.rpprEntry.flag_status = data.flag_status;
    state.rpprEntry.show = true;
  },
  RESET_RPPR_ENTRY(state) {
    state.rpprEntry.mode = 1;
    state.rpprEntry.valid = true;
    state.rpprEntry.id = '';
    state.rpprEntry.id_project = '';
    state.rpprEntry.id_user = '';
    state.rpprEntry.id_year = '';
    state.rpprEntry.year_schooling_completed = '';
    state.rpprEntry.home_institution = '';
    state.rpprEntry.gov_fiscal_year = '';
    state.rpprEntry.percent_effort = '';
    state.rpprEntry.appointment_term = '';
    state.rpprEntry.appointment_term_other = '';
    state.rpprEntry.funding_support = '';
    state.rpprEntry.person_months = '';
    state.rpprEntry.contribution = '';
    state.rpprEntry.collab_internationally = '';
    state.rpprEntry.collab_countries = '';
    state.rpprEntry.travel_internationally = '';
    state.rpprEntry.travel_countries = [];
    state.rpprEntry.flag_status = 1;
  },
  ADD_ITEM(state) {
    const data = {
      country: '',
      years: '',
      months: '',
      days: '',
    };
    state.rpprEntry.travel_countries.push(data);
  },
  REMOVE_ITEM(state, { id }) {
    state.rpprEntry.travel_countries.splice(id, 1);
  },
  SET_PARTICIPATION(state, {data}) {
    state.rpprEntry.participation = data;
  },
  OPEN_PASSWORD(state , {data}) {
    state.password.valid = true;
    state.password.id_user = data.id_user;
    state.password.password = '';
    state.password.passwordConfirmation = '';
    state.password.show = true;
  },
  OPEN_PROFILE(state) {
    state.profile.show = true;
  },
  READ_ACTIVE_PROJECTS(state, { data }) {
    state.activeUser.projects = data.projects;
  },
  SET_EXPORT(state, { data }) {
    state.filters.export = data;
  },
  SET_RESEARCHER_EXPORT(state, { data }) {
    state.researcherFilters.export = data;
  },
  SET_TRAINEE_EXPORT(state, { data }) {
    state.traineeFilters.export = data;
  },
  SET_OTHER_USER_EXPORT(state, { data }) {
    state.otherUserFilters.export = data;
  },
  SET_RPPR_EXPORT(state, { data }) {
    state.rpprFilters.export = data;
  },

  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateRpprField(state, field) {
    updateField(state.rpprEntry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },

  updateResearcherFilterField(state, field) {
    updateField(state.researcherFilters, field);
  },
  updateTraineeFilterField(state, field) {
    updateField(state.traineeFilters, field);
  },
  updateOtherUserFilterField(state, field) {
    updateField(state.otherUserFilters, field);
  },
  updateRPPRFilterField(state, field) {
    updateField(state.rpprFilters, field);
  },

  updateProfileField(state, field) {
    updateField(state.profile, field);
  },
  updatePasswordField(state, field) {
    updateField(state.password, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearUsers: ({ commit }) => {
    commit('CLEAR_USERS');
  },
  clearTrainees({commit}) {
    commit('CLEAR_TRAINEES');
  },
  clearResearchers({commit}) {
    commit('CLEAR_RESEARCHERS');
  },
  clearOtherUsers({commit}) {
    commit('CLEAR_OTHER_USERS');
  },
  clearRppr({commit}) {
    commit('CLEAR_RPPR');
  },
  clearAllUserLists({commit}) {
    commit('CLEAR_ALL_USER_LISTS');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/users', data, { headers: getHeader() });
      /*Users*/
      if(data.sectionStored === 1 || data.sectionStored === 3) {
        commit('FETCH', { list: response.data });
      }
      /*Admin Researchers List*/
      else if(data.sectionStored === 5) {
        commit('FETCH_RESEARCHERS', { list: response.data });
      }
      /*Trainees*/
      else if(data.sectionStored === 4 || data.sectionStored === 6 || data.sectionStored === 9) {
        commit('FETCH_TRAINEES', { list: response.data });
      }
      /*Admin Other Users*/
      else if(data.sectionStored === 7) {
        commit('FETCH_OTHER_USERS', { list: response.data });
      }
      /*RPPR*/
      else if(data.sectionStored === 2 || data.sectionStored === 8 || data.sectionStored === 10) {
        commit('FETCH_RPPR', { list: response.data });
      }
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/users/create', data, { headers: getHeader() });
      /*Users*/
      if(data.sectionStored === 1 || data.sectionStored === 3) {
        commit('CREATE', { data: response.data });
      }
      /*Admin Researchers List*/
      else if(data.sectionStored === 5) {
        commit('CREATE_RESEARCHERS', { data: response.data });
      }
      /*Trainees*/
      else if(data.sectionStored === 4 || data.sectionStored === 6 || data.sectionStored === 9) {
        commit('CREATE_TRAINEES', { data: response.data });
      }
      /*Admin Other Users*/
      else if(data.sectionStored === 7) {
        commit('CREATE_OTHER_USERS', { data: response.data });
      }
      /*RPPR*/
      else if(data.sectionStored === 2 || data.sectionStored === 8 || data.sectionStored === 10) {
        commit('CREATE_RPPR', { data: response.data });
      }
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/users/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/users/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
      /*Users*/
      if(data.sectionStored === 1 || data.sectionStored === 3) {
        commit('UPDATE', { data: response.data });
      }
      /*Admin Researchers List*/
      else if(data.sectionStored === 5) {
        commit('UPDATE_RESEARCHERS', { data: response.data });
      }
      /*Trainees*/
      else if(data.sectionStored === 4 || data.sectionStored === 6 || data.sectionStored === 9) { 
        commit('UPDATE_TRAINEES', { data: response.data });
      }
      /*Admin Other Users*/
      else if(data.sectionStored === 7) {
        commit('UPDATE_OTHER_USERS', { data: response.data });
      }
      /*RPPR*/
      else if(data.sectionStored === 2 || data.sectionStored === 8 || data.sectionStored === 10) {
        commit('UPDATE_RPPR', { data: response.data });
      }
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/users/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/users/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post('/api/users', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data});
    } catch (error) {
    }
  },
  async valueList({ commit }, id) {
    try {
      const response = await axios.post('api/users/valuelist/1', { headers: getHeader() });
      commit('VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/users/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async readProfile({ commit }, data) {
    try {
      const response = await axios.post('/api/users/profile', data, { headers: getHeader() });
      commit('READ_PROFILE', { data: response.data });
    } catch (error) {

    }
  },
  async getProfile({ commit }, data) {
    try {
      const response = await axios.post('/api/users/profile', data, { headers: getHeader() });
      commit('GET_PROFILE', { data: response.data });
    } catch (error) {

    }
  },
  async updateProfile({ commit }, data) {
    try {
      const response = await axios.post('/api/users/profile/update', data, { headers: getHeader() });
      commit('UPDATE_PROFILE', { data: response.data });
      return response.data
    } catch (error) {

    }
  },
  async readActiveProjects({ commit }, id) {
    try {
      const response = await axios.get(`/api/users/${id}`, { headers: getHeader() });
      commit('READ_ACTIVE_PROJECTS', { data: response.data });
    } catch (error) {

    }
  },
  async email({ commit }, data) {
    try {
      const response = await axios.post('/api/users/email', data, { headers: getHeader() });
      return response;
    } catch (error) {
      return error
    }
  },
  async invite({ commit }, data) {
    try {
      const response = await axios.post('/api/users/invite', data, { headers: getHeader() });
      return response ;
    } catch (error) {
      return error
    }
  },
  async readByEmail({ commit }, data) {
    try {
      const response = await axios.post('/api/users/search/email', data, { headers: getHeader() });
      commit('SEARCH_EMAIL', { data: response.data });
    } catch (error) {

    }
  },
  async rpprEntry({ commit }, id) {
    try {
      const response = await axios.get(`/api/rppr/${id}`, { headers: getHeader() });
      commit('RPPR_ENTRY', { data: response.data});
    } catch (error) {

    }
  },
  async createRppr({ commit }, data) {
    try {
      const response = await axios.post('/api/rppr/create', data, { headers: getHeader() });
      commit('UPDATE_RPPR', { data: response.data });
    } catch (error) {

    }
  },
  async updateRppr({ commit }, data) {
    try {
      const response = await axios.put(`/api/rppr/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE_RPPR', { data: response.data });
    } catch (error) {

    }
  },
  addItem({ commit }) {
    commit('ADD_ITEM');
  },
  removeItem({ commit }, id) {
    commit('REMOVE_ITEM', { id });
  },
  setParticipation({commit}, data) {
    commit('SET_PARTICIPATION', {data: data});
  },
  newRpprEntry({commit}, data) {
    commit('NEW_RPPR_ENTRY', {data: data});
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
  resetRpprEntry({commit}) {
    commit('RESET_RPPR_ENTRY');
  },
  resetProfile({commit}) {
    commit('RESET_PROFILE');
  },
  openPassword({commit}, data) {
    commit('OPEN_PASSWORD', {data: data });
  },
  openProfile({commit}) {
    commit('OPEN_PROFILE');
  },
  setAuthUser: ({ commit }, userObj) => {
    commit('SET_AUTH_USER', userObj);
  },
  setAuthUserType: ({ commit }, id) => {
    commit('SET_AUTH_USER_TYPE', id);
  },
  setActiveUser: ({ commit }, userObj) => {
    commit('SET_ACTIVE_USER', userObj);
  },
  setActiveUserType: ({ commit }, id) => {
    commit('SET_ACTIVE_USER_TYPE', id);
  },
  setActiveProject: ({ commit }, project) => {
    commit('SET_ACTIVE_PROJECT', project);
  },
  clearActiveProject: ({ commit }) => {
    commit('CLEAR_ACTIVE_PROJECT');
  },
  clearActiveUserType: ({ commit }) => {
    commit('CLEAR_ACTIVE_USER_TYPE');
  },
  clearAuthUserType: ({ commit }) => {
    commit('CLEAR_AUTH_USER_TYPE');
  },
  setAppMode: ({ commit }, mode) => {
    commit('SET_APP_MODE', mode);
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
  setAdminMode: ({ commit }, mode) => {
    commit('SET_ADMIN_MODE', mode);
  },
  setExport: ({ commit }, data) => {
    commit('SET_EXPORT', { data });
  },
  setResearcherExport: ({ commit }, data) => {
    commit('SET_RESEARCHER_EXPORT', { data });
  },
  setTraineeExport: ({ commit }, data) => {
    commit('SET_TRAINEE_EXPORT', { data });
  },
  setOtherUserExport: ({ commit }, data) => {
    commit('SET_OTHER_USER_EXPORT', { data });
  },
  setRpprExport: ({ commit }, data) => {
    commit('SET_RPPR_EXPORT', { data });
  },
  exportResearcherFilters: ({ commit }, data) => {
    commit('EXPORT_RESEARCHER_FILTERS', {data});
  },
  exportTraineeFilters: ({ commit }, data) => {
    commit('EXPORT_TRAINEE_FILTERS', {data});
  },
};

const getters = {
  getAuthUser: (state, getters) => state.authUser,
  getAuthUserType: (state, getters) => state.authUserType,
  getActiveUser: (state, getters) => state.activeUser,
  getActiveUserType: (state, getters) => state.activeUserType,
  getActiveProject: (state, getters) => state.activeProject,
  getAdminMode: (state, getters) => state.adminMode,
  getUser: (state, getters) => state.user,
  getUsers: (state, getters) => state.users,
  getExport: (state, getters) => state.export.data,
  getFilters: (state, getters) => state.filters,
  getResearcherFilters: (state, getters) => state.researcherFilters,
  getTraineeFilters: (state, getters) => state.traineeFilters,
  getOtherUserFilters: (state, getters) => state.otherUserFilters,
  getRPPRFilters: (state, getters) => state.rpprFilters,
  getAdvisors: (state, getters) => state.entry.advisors,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },

  getResearcherFilterField(state) {
    return getField(state.researcherFilters);
  },
  getTraineeFilterField(state) {
    return getField(state.traineeFilters);
  },
  getOtherUserFilterField(state) {
    return getField(state.otherUserFilters);
  },
  getRPPRFilterField(state) {
    return getField(state.rpprFilters);
  },

  getProfileField(state) {
    return getField(state.profile);
  },
  getPasswordField(state) {
    return getField(state.password);
  },
  getRpprField(state) {
    return getField(state.rpprEntry);
  },
  getEntry: (state, getters) => state.entry,
  getRpprEntry: (state, getters) => state.rpprEntry,
  getProfile: (state, getters) => state.profile,
  getUserProfile: (state, getters) => state.userProfile,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
