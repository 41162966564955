import Vue from 'vue'
import Vuex from 'vuex'

import usersStore from '@/store/modules/users';
import helpTopicsStore from '@/store/modules/helptopics';
import messagesStore from '@/store/modules/messages';
import navigationStore from '@/store/modules/navigation';
import progressStore from '@/store/modules/progress';
import projectsStore from '@/store/modules/projects';
import cohortsStore from '@/store/modules/cohorts';
import jurisdictionsStore from '@/store/modules/jurisdictions';
import institutionsStore from '@/store/modules/institutions';
import departmentsStore from '@/store/modules/departments';
import faqsStore from '@/store/modules/faqs';
import participantsStore from '@/store/modules/participants';
import fundingsStore from '@/store/modules/fundings';
import publicationsStore from '@/store/modules/publications';
import patentsStore from '@/store/modules/patents';
import presentationsStore from '@/store/modules/presentations';
import outcomesStore from '@/store/modules/outcomes';
import fellowshipsStore from '@/store/modules/fellowships';
import emailsStore from '@/store/modules/emails';
import dissertationsStore from '@/store/modules/dissertations';
import valueListsStore from '@/store/modules/valuelists';
import participationStore from '@/store/modules/participation';
import rpprOrganizationsStore from '@/store/modules/rpprorganizations';
import rpprCollaboratorsStore from '@/store/modules/rpprcollaborators';
import baselinesStore from '@/store/modules/baselines';
import countriesStore from '@/store/modules/countries';
import rpprStore from '@/store/modules/rppr';
import yearsStore from '@/store/modules/years';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    usersStore,
    helpTopicsStore,
    messagesStore,
    navigationStore,
    progressStore,
    projectsStore,
    cohortsStore,
    jurisdictionsStore,
    institutionsStore,
    departmentsStore,
    faqsStore,
    participantsStore,
    fundingsStore,
    publicationsStore,
    patentsStore,
    presentationsStore,
    outcomesStore,
    fellowshipsStore,
    emailsStore,
    dissertationsStore,
    valueListsStore,
    participationStore,
    rpprOrganizationsStore,
    rpprCollaboratorsStore,
    baselinesStore,
    countriesStore,
    rpprStore,
    yearsStore,
  },
  strict: debug,
})
