import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login.vue';
import PasswordReset from '@/views/PasswordReset.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/login',
    name: 'login2',
    component: Login,
  },
  {
    path: '/logout',
    name: 'logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
  },
  {
    path: '/password/reset/:token',
    name: 'passwordreset',
    component: PasswordReset,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/settings/projects',
    name: 'settingsProjects',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "projects" */ '../views/SettingsProjects.vue')
  },
  {
    path: '/settings/projects/details/:id',
    name: 'projectDetail',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "projectDetail" */ '../views/ProjectDetail.vue')
  },
  {
    path: '/settings/cohorts',
    name: 'settingsCohorts',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "cohorts" */ '../views/SettingsCohorts.vue')
  },
  {
    path: '/settings/jurisdictions',
    name: 'settingsJurisdictions',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "jurisdictions" */ '../views/SettingsJurisdictions.vue')
  },
  {
    path: '/settings/years',
    name: 'settingsYears',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "years" */ '../views/SettingsYears.vue')
  },
  {
    path: '/settings/institutions',
    name: 'settingsInstitutions',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "institutions" */ '../views/SettingsInstitutions.vue')
  },
  {
    path: '/settings/institutions/details/:id',
    name: 'institutionDetail',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "institutionDetail" */ '../views/InstitutionDetail.vue')
  },
  {
    path: '/settings/users',
    name: 'settingsUsers',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "users" */ '../views/SettingsUsers.vue')
  },
  {
    path: '/settings/countries',
    name: 'settingsCountries',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "countries" */ '../views/SettingsCountries.vue')
  },
  {
    path: '/settings/users/details/:id',
    name: 'userDetail',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "projectDetail" */ '../views/UserDetail.vue')
  },
  {
    path: '/settings/helptopics',
    name: 'settingsHelpTopics',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "helpTopics" */ '../views/SettingsHelpTopics.vue')
  },
  {
    path: '/settings/faq',
    name: 'settingsFAQ',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "faq" */ '../views/SettingsFAQ.vue')
  },
  {
    path: '/settings/notifications',
    name: 'settingsNotifications',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "notifications" */ '../views/SettingsNotifications.vue')
  },
  {
    path: '/settings/emails',
    name: 'settingsEmails',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "emails" */ '../views/SettingsEmails.vue')
  },
  {
    path: '/helptopics',
    name: 'helpTopics',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "helptopics" */ '../views/HelpTopics.vue')
  },
  {
    path: '/projects',
    name: 'projectSelector',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "projectSelector" */ '../views/ProjectSelector.vue')
  },
  {
    path: '/funding',
    name: 'fundingList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "fundingList" */ '../views/FundingList.vue')
  },
  {
    path: '/publications',
    name: 'publicationsList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "publicationsList" */ '../views/PublicationsList.vue')
  },
  {
    path: '/patents',
    name: 'patentsList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "patentsList" */ '../views/PatentsList.vue')
  },
  {
    path: '/presentations',
    name: 'presentationsList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "presentationsList" */ '../views/PresentationsList.vue')
  },
  {
    path: '/fellowships',
    name: 'fellowshipsList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "fellowshipsList" */ '../views/FellowshipsList.vue')
  },
  {
    path: '/outcomes',
    name: 'outcomesList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "outcomesList" */ '../views/OutcomesList.vue')
  },
  {
    path: '/dissertations',
    name: 'dissertationsList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "dissertationsList" */ '../views/DissertationsList.vue')
  },
  {
    path: '/trainees',
    name: 'traineesList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "traineesList" */ '../views/TraineesList.vue')
  },
  {
    path: '/administrator',
    name: 'administrator',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "administrator" */ '../views/Administrator.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/baseline',
    name: 'baseline',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "baseline" */ '../views/Baseline.vue')
  },
  {
    path: '/rppr',
    name: 'rppr',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "rppr" */ '../views/RPPRList.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '/profilecheck',
    name: 'profileCheck',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "profileCheck" */ '../views/ProfileCheck.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

/**
 * Protect routes
 */
router.beforeEach((to, from, next) => {

  let logout = 0;
  /* If going to logout route */
  if (to.name === 'logout') {
    window.localStorage.removeItem('timer');
  }
  /* If login route */ 
  else if (to.name === 'login' || to.name === 'login2' || to.name === 'passwordreset') {
    /* Remain on layout */
    
  }
  /* All other routes */ 
  else {
    /* Grab previous timer */
    let prevDate = JSON.parse(window.localStorage.getItem('timer'));
    /* If previous date is null */
    if (prevDate === null) {
      /* Set previous date with current timestamp */
      prevDate = new Date();
    }
    /* Turn previous date into date */
    else {
      prevDate = new Date(prevDate);
    }
    /* Grab current Date */
    const currentDate = new Date();
    /* Calculate difference between dates */
    const diff = (currentDate.getTime() - prevDate.getTime()) / 1000;
    /* If difference is greater than 8 hours */
    if (diff > 14400) {
      /* Set logout variable */
      logout = 1;
    }
    /* Update timer */
    else {
      window.localStorage.setItem('timer', JSON.stringify(new Date()));
    }
  }

  /* If logout variable was set */
  if (logout === 1) {
    next({ name: 'logout' });
  }
  /* If going to logout route */
  else if (to.meta.requiresAuth) {
    const authUser = JSON.parse(window.localStorage.getItem('authUser'));
    if (authUser && authUser.access_token) {
      next();
    } else {
      next({ name: 'login' });
    }
  }
  else {
    next();
  }
});

export default router
