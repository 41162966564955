import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  institution: {},
  institutions: [],
  institutionsValueList: [],
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /*-----ADD ENTRY FIELDS BELOW-----*/
    name: '',
    id_jurisdiction: '',
    flag_status: '',
  },
  filters: {
    page: '',
    sectionStored: '',
    /*-----ADD FILTER FIELDS BELOW-----*/
    id_project: '',
    name: '',
    id_jurisdiction: '',
    flag_status: '',
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.institutions = [];
    state.institutionsValueList = [];
    state.filters = {
      page: '',
      sectionStored: '',
      
      id_project: '',
      name: '',
      id_jurisdiction: '',
      flag_status: '',
    };
  },
  CLEAR_INSTITUTIONS(state) {
    state.institutions = [];
  },
  FETCH(state, { list }) {
    state.institutions = list;
  },
  CREATE(state, { data }) {
    state.institutions.data.push(data);
  },
  READ(state, { data }) {
    state.institution = data;
  },
  UPDATE(state, { data }) {
    const idx = state.institutions.data.map(item => item.id).indexOf(data.id);
    state.institutions.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.institutions.data.map(item => item.id).indexOf(id);
    state.institutions.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.name = data.name;
    state.entry.id_jurisdiction = data.id_jurisdiction;
    state.entry.flag_status = data.flag_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.name = '';
    state.entry.id_jurisdiction = '';
    state.entry.flag_status = 1;
  },
  VALUE_LIST(state, { list }) {
    state.institutionsValueList = list;
  },
  FETCH_BY_PROJECT(state, { list }) {
    state.institutionsValueList = list;
    let data = {
      id: 0,
      name: 'Other'
    }
    state.institutionsValueList.push(data)
  },
  FETCH_BY_JURISDICTION(state, { list }) {
    state.institutionsValueList = list;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearInstitutions: ({ commit }) => {
    commit('CLEAR_INSTITUTIONS');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/institutions', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/institutions/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/institutions/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/institutions/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/institutions/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/institutions/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }) {
    try {
      const response = await axios.get('/api/institutions/valuelist/1', { headers: getHeader() });
      commit('VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async byProject({ commit }, data) {
    try {
      const response = await axios.post('/api/institutions/project', data, { headers: getHeader() });
      commit('FETCH_BY_PROJECT', { list: response.data });
    } catch (error) {

    }
  },
  async byJurisdiction({ commit }, data) {
    try {
      const response = await axios.post('/api/institutions/jurisdiction', data, { headers: getHeader() });
      commit('FETCH_BY_JURISDICTION', { list: response.data });
    } catch (error) {

    }
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getInstitution: (state, getters) => state.institution,
  getinstitutions: (state, getters) => state.institutions,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
