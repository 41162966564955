<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <v-card flat>
                <v-card-actions>
                    <v-btn color="primary" text @click="goLogin()">
                        <v-icon left>fal fa-chevron-left</v-icon>
                        <span>Login</span>
                    </v-btn>
                </v-card-actions>
                <v-row class="pa-4">
                    <v-img
                        v-if="$vuetify.theme.dark === false"
                        contain
                        height="150px"
                        :src="require('@/assets/logo_login_light.png')"
                    />
                    <v-img
                        v-if="$vuetify.theme.dark === true"
                        contain
                        height="150px"
                        :src="require('@/assets/logo_login_dark.png')"
                    />
                </v-row>
                <p
                    class="text-h6 text-center"
                    :class="
                        $vuetify.theme.dark === false
                            ? 'primary--text'
                            : 'secondary--text'
                    "
                >
                    Data Outcomes Portal (DOP)
                </p>
                <v-card-text>
                    <v-form
                        ref="forgetPassword"
                        @submit.prevent="resetPassword()"
                    >
                        <v-text-field
                            v-model="email"
                            prepend-icon="fal fa-user-circle"
                            label="Email Address"
                            type="text"
                            required
                            :rules="rules.email"
                        />
                        <v-text-field
                            v-model="password"
                            prepend-icon="fal fa-lock"
                            label="Password"
                            type="password"
                            required
                            :rules="rules.password"
                        />
                        <v-text-field
                            v-model="passwordConfirmation"
                            prepend-icon="fal fa-lock"
                            label="Confirm Password"
                            type="password"
                            required
                            :rules="rules.passwordConfirm"
                        />
                        <v-btn rounded color="primary" block type="submit">
                            Reset Password
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import Message from "@/components/Message";

export default {
    name: "PasswordReset",
    data() {
        return {
            email: "",
            password: "",
            passwordConfirmation: "",
            valid: true,
            rules: {
                email: [(v) => !!v || "Required"],
                password: [
                    (v) => !!v || "Required",
                    (v) =>
                        (v && v.length >= 8) ||
                        "Password must have at least 8 characters",
                ],
                passwordConfirm: [
                    (v) => !!v || "Required",
                    (v) => v === this.password || "Passwords must match",
                ],
            },
        };
    },
    computed: {
        ...mapState({
            usersStore: (state) => state.usersStore,
            messagesStore: (state) => state.messagesStore,
        }),
    },
    methods: {
        resetPassword() {
            if (this.$refs.forgetPassword.validate()) {
                /* Grab data from form */
                const data = {
                    token: this.$route.params.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                };
                axios
                    .post("/api/password/reset", data)
                    // eslint-disable-next-line
                    .then((response) => {
                        /* Show success notification */
                        let data = {
                            color: "success",
                            text:
                                "Password has been reset.  Return to login and use your new password.",
                        };
                        this.$store.commit("messagesStore/setMessage", data);

                        this.email = "";
                        this.password = "";
                        this.passwordConfirmation = "";
                        this.$refs.forgetPassword.resetValidation();

                        // Force user back to login screen
                        this.$router.push({ name: "login" });
                    })
                    .catch((error) => {
                        /* Show success notification */
                        let data = {
                            color: "error",
                            text:
                                'There was an error updating the password.  If it has been more than 2 weeks from receiving the password link, go back to the login page and click the "Forgot Your Password" again.',
                        };
                        this.$store.commit("messagesStore/setMessage", data);
                        /* Show login error */
                        // eslint-disable-next-line
                    });
                this.valid = true;
            }
        },
        goLogin() {
            this.$router.push({ name: "login" });
        },
    },
};
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
button {
    outline: none;
}
</style>
