const state = {
  progress: false,
};

const mutations = {
  SET_PROGRESS(state, value) {
    state.progress = value;
  },
  CLEAR_STORE(state) {
    state.progress = false;
  },
};

const actions = {
  set: ({ commit }, value) => {
    commit('SET_PROGRESS', value);
  },
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
