<template>
  <div>
    <v-btn
      text
      small
      color="primary"
      @click="newEntry()"
    >
      Forgot Your Password?
    </v-btn>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      max-width="500"
    >
      <v-card
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          Forgot Password<v-spacer /><v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-container fluid>
              <v-row dense>
                <!--Email-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="email"
                    label="Email"
                    placeholder=" "
                    required
                    :rules="rules.email"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ForgotPassword',
  data() {                               
    return {
      valid: true,
      show: false,
      email: '',
      rules: {
		    email: [
			    v => !!v || 'Required',
		    ],
	    },
    }
  },
  computed: {
	  ...mapState({
      messagesStore: state => state.messagesStore,
	  }),
  },
  methods: {

    async newEntry() {
      this.valid = true;
      this.show = true;
    },

    cancelEntry() {
      this.$refs.entryForm.reset()
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        /*Grab data from form*/
        const data = {
            email:this.email
        }

        axios.post('/api/password/checkuser', data)
          .then(response => {

              if (response.data) {

                  /*POST forgot password*/
                  axios.post('/api/password/email', data)
                      .then(response => {
                        /* Show success notification */
                        let message = {
                          color: 'success',
                          text: 'Password reset email has been sent.',
                        };
                        this.$store.commit('messagesStore/setMessage', message);
                      })
                      .catch(function(error){
                        /* Show success notification */
                        let message = {
                          color: 'error',
                          text: 'There was an error with the request.',
                        };
                        this.$store.commit('messagesStore/setMessage', message);
                      });
              }
              else {/* Show success notification */
                let message = {
                  color: 'error',
                  text: 'There was an error with the request.',
                };
                this.$store.commit('messagesStore/setMessage', message);
              }
          })
          .catch(function(error){
            let message = {
              color: 'error',
              text: 'Invalid user email address.',
            };
            this.$store.commit('messagesStore/setMessage', message);
          });
        this.cancelEntry();
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
