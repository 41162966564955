export const appName = "ILI T2Dop";
export const company = "Integrated Learning Innovations, Inc.";
export const clientID = process.env.VUE_APP_CLIENT_ID;
export const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
export const siteURL = process.env.VUE_APP_SITE_URL;
export const siteVersion = "4.3.17";
export const supportEmail = "help@ilearningi.com";

export const doiURL = "https://api.crossref.org/v1/works/http://dx.doi.org/";

export const getHeader = function authHeader() {
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${tokenData.access_token}`,
  };
  return headers;
};
