<template>
  <div>
    <v-tooltip
      v-if="usersStore.appMode === 1"
      bottom
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="openEntry()"
        >
          <v-icon
            color="primary"
          >
            fal fa-envelope
          </v-icon>
        </v-btn>
      </template>
      <span>Email Support</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      max-width="800"
    >
      <v-card
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          <span v-if="mode === 1">Email Support</span><span v-else>Email</span>
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Close
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
          >
            <v-container fluid>
              <v-row dense>
                <!--To-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <text-display
                    v-if="mode === 1"
                    :display-string="to"
                    :label="'To'"
                  />
                  <v-text-field
                    v-else
                    v-model="to"
                    label="To"
                    placeholder=" "
                    required
                    :rules="rules.to"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--CC-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <text-display
                    v-if="mode === 1"
                    :display-string="cc"
                    :label="'CC'"
                  />
                  <v-text-field
                    v-else
                    v-model="cc"
                    label="CC"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Subject-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <text-display
                    v-if="mode === 1"
                    :display-string="subject"
                    :label="'Subject'"
                  />
                  <v-text-field
                    v-else
                    v-model="subject"
                    label="Subject"
                    placeholder=" "
                    required
                    :rules="rules.subject"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Body-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-textarea
                    v-model="body"
                    label="Message"
                    placeholder=" "
                    required
                    :rules="rules.body"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import {supportEmail} from '@/env'
import TextDisplay from '@/components/TextDisplay';

const { mapFields } = createHelpers({
  getterType: 'emailsStore/getEntryField',
  mutationType: 'emailsStore/updateEntryField',
});

export default {
  name: 'Email',
  /*1 = Support Email, Else General User Email*/
  emailSection: {
    type: Number,
    required: false,
  },
  components: {
    TextDisplay,
  },
  data() {
    return {
      rules: {
        to: [
			    v => !!v || 'Required',
        ],
        subject: [
			    v => !!v || 'Required',
		    ],
		    body: [
			    v => !!v || 'Required',
		    ],
      },
    }
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      emailsStore: state => state.emailsStore
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'section',

      'id_user',
      'to',
      'cc',
      'subject',
      'body',
      'from',
    ]),

  },
  methods: {
    openEntry() {
      let user = this.$store.getters['usersStore/getAuthUser'];
      this.valid = false;
      this.mode = 1;
      this.section = 1;
      this.id_user = '';
      this.to = supportEmail;
      this.cc = user.email;
      this.subject = 'Data Outcomes Portal (DOP) Support Request';
      this.body = '';
      this.from = '';
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        let user = this.$store.getters['usersStore/getAuthUser'];
        if(this.section === 1) {
          this.subject = 'Data Outcomes Portal (DOP) Support Request' + ' - ' + user.name_reverse +  ' - ' + user.email
        }
        let email = this.$store.getters['emailsStore/getEntry'];
        let response;
        if(this.section === 1) {
          response = await this.$store.dispatch('emailsStore/emailSupport', email);
        }
        else {
          response = await this.$store.dispatch('usersStore/email', email);
        }
        this.show = false;
        let message;
        if(response.status === 200) {
          message = {
            color: 'success',
            text: 'Email has been sent.',
          }
        }
        else {
          message = {
            color: 'error',
            text: 'There is an issue with sending the email at this time.',
          }
        }
        this.$store.commit('messagesStore/setMessage', message);
      }
    },
  },
}
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>