import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  rpprOrganizations: [],
  export: [],
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /*-----ADD ENTRY FIELDS BELOW-----*/
    name: '',
    type: '',
    location: '',
    contribution: [],
    contribution_detail: '',
    years: [],
    flag_status: '',
  },
  filters: {
    page: '',
    export: '',
    /*-----ADD FILTER FIELDS BELOW-----*/
    id_project: '',
    name: '',
    flag_status: ''
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.rpprOrganizations = [];
    state.entry = {
      valid: '', 
      mode: '',
      id: '',
      /*-----ADD ENTRY FIELDS BELOW-----*/
      name: '',
      type: '',
      location: '',
      contribution: [],
      contribution_detail: '',
      years: [],
      flag_status: '',
    },
    state.filters = {
      page: '',
      export: '',
      /*-----ADD FILTER FIELDS BELOW-----*/
      id_project: '',
      name: '',
      flag_status: '',
    };
  },
  EXPORT_FILTERS(state, {data}) {
    state.filters.id_project = data.id_project
    state.filters.export = data.export
    state.filters.name = ''
    state.filters.flag_status = '';
  },
  FETCH(state, { list }) {
    state.rpprOrganizations = list;
  },
  CREATE(state, { data }) {
    state.rpprOrganizations.data.push(data);
  },
  READ(state, { data }) {
    state.rpprOrganization = data;
  },
  UPDATE(state, { data }) {
    const idx = state.rpprOrganizations.data.map(item => item.id).indexOf(data.id);
    state.rpprOrganizations.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.rpprOrganizations.data.map(item => item.id).indexOf(id);
    state.rpprOrganizations.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.id_project = data.id_project;
    state.entry.name = data.name;
    state.entry.type = data.type;
    state.entry.location = data.location;
    state.entry.contribution = data.contribution;
    state.entry.contribution_detail = data.contribution_detail;
    state.entry.years = data.years;
    state.entry.flag_status = data.flag_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.id_project = '';
    state.entry.name = '';
    state.entry.type = '';
    state.entry.location = '';
    state.entry.contribution = [];
    state.entry.contribution_detail = '';
    state.entry.years  = [];
    state.entry.flag_status = 1;
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  SET_EXPORT(state, { data }) {
    state.filters.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/rpprorganizations', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/rpprorganizations/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/rpprorganizations/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/rpprorganizations/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/rpprorganizations/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/rpprorganizations/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post('/api/rpprorganizations', data, { headers: getHeader() });
      commit('EXPORT_EXCEL', { data: response.data});
    } catch (error) {

    }
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
  setExport: ({ commit }, data) => {
    commit('SET_EXPORT', { data });
  },
  exportFilters({commit}, data) {
    commit('EXPORT_FILTERS', {data});
  },
};

const getters = {
  getRPPROrganizations: (state, getters) => state.rpprOrganizations,
  getExport: (state, getters) => state.export,
  getFilters: (state, getters) => state.filters,
  getEntry: (state, getters) => state.entry,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
