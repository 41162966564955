const state = {
  message: {
    color: '',
    text: '',
    show: false
  },
};

const mutations = {
  setMessage(state, message) {
    state.message = message;
  },
  CLEAR_STORE(state) {
    state.message = {
      color: '',
      text: '',
      show: false
    };
  },
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
