import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  years: [],
  yearsValueList: [],
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_project: '',
    date_start: '',
    date_end: '',
    number: '',
    date1: '',
    date2: '',
    flag_show: '',
  },
  filters: {
    page: '',
    /*-----ADD FILTER FIELDS BELOW-----*/
    id_project: ''
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.years = [];
    state.yearsValueList = [];
    state.filters = {
      page: '',
      id_project: '',
    };
  },
  FETCH(state, { list }) {
    state.years = list;
  },
  CREATE(state, { data }) {
    state.years.data.push(data);
  },
  READ(state, { data }) {
    state.year = data;
  },
  UPDATE(state, { data }) {
    const idx = state.years.data.map(item => item.id).indexOf(data.id);
    state.years.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.years.data.map(item => item.id).indexOf(id);
    state.years.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.id_project = data.id_project;
    state.entry.date1 = data.date_start;
    state.entry.date2 = data.date_end;
    state.entry.number = data.number;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = '';
    state.entry.id_project = '';
    state.entry.date_start = '';
    state.entry.date_end = '';
    state.entry.number = '';
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/years', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/years/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/years/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/years/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/years/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/years/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getYears: (state, getters) => state.years,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
