import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";
import { stat } from "fs";

const state = {
  fellowships: [],
  fellowship: {},
  export: [],
  entry: {
    show: false,
    valid: "",
    mode: "",
    view: "",

    id: "",

    activeUser: "",

    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_user: "",
    id_project: "",
    type: "",
    type_other: "",
    title: "",
    organization: "",
    source: "",
    date_submitted: "",
    date_month: "",
    date_year: "",
    award_number: "",
    amount_requested: "",
    amount_awarded: "",
    date_awarded_month: "",
    date_awarded_month_end: "",
    date_awarded_year: "",
    date_awarded_year_end: "",
    date_awarded: "",
    flag_status: "",
    flag_show: "",
  },
  filters: {
    page: "",
    view: "",
    export: "",
    id_user: "",
    id_project: "",
    /*-----ADD FILTER FIELDS BELOW-----*/
    title: "",
    type: "",
    organization: "",
    flag_status: "",
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.fellowships = [];
    state.fellowship = {};
    state.export = [];
    state.filters = {
      page: "",
      view: "",
      export: "",
      id_user: "",
      id_project: "",
      /*-----ADD FILTER FIELDS BELOW-----*/
      title: "",
      type: "",
      organization: "",
      flag_status: "",
    };
  },
  CLEAR_LIST(state) {
    state.fellowships = [];
  },
  EXPORT_FILTERS(state, { data }) {
    state.filters.id_project = data.id_project;
    state.filters.export = 1;
    state.filters.view = 2;
    state.filters.id_user = "";
    state.filters.title = "";
    state.filters.type = "";
    state.filters.organization = "";
    state.filters.flag_status = "";
  },
  FETCH(state, { list }) {
    state.fellowships = list;
  },
  CREATE(state, { data }) {
    if (data.data) {
      state.fellowships = data;
    } else {
      state.fellowships.data.unshift(data);
      if (state.entry.view === 1)
        state.fellowships.data = state.fellowships.data.slice(0, 4);
    }
  },
  READ(state, { data }) {
    state.fellowship = data;
  },
  UPDATE(state, { data }) {
    if (data.data) {
      state.fellowships = data;
    } else {
      const idx = state.fellowships.data
        .map((item) => item.id)
        .indexOf(data.id);
      state.fellowships.data.splice(idx, 1, data);
    }
  },
  DELETE(state, { id }) {
    const idx = state.fellowships.data.map((item) => item.id).indexOf(id);
    state.fellowships.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.fellowship = data;
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /*-----ADD ENTRY FIELDS BELOW-----*/
    state.entry.id_user = data.id_user;
    state.entry.id_project = data.id_project;
    state.entry.type = data.type;
    state.entry.type_other = data.type_other;
    state.entry.title = data.title;
    state.entry.organization = data.organization;
    state.entry.source = data.source;
    state.entry.date_submitted = data.date_submitted;
    state.entry.date_month = data.date_month;
    state.entry.date_year = data.date_year;
    state.entry.award_number = data.award_number;
    state.entry.amount_requested = data.amount_requested;
    state.entry.amount_awarded = data.amount_awarded;
    state.entry.date_awarded_month = data.date_awarded_month;
    state.entry.date_awarded_month_end = data.date_awarded_month_end;
    state.entry.date_awarded_year = data.date_awarded_year;
    state.entry.date_awarded_year_end = data.date_awarded_year_end;
    state.entry.date_awarded = data.date_awarded;
    state.entry.flag_status = data.flag_status;
    state.entry.flag_show = data.flag_show;

    state.entry.show = true;
  },
  RESET_ENTRY(state, { id }) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = "";
    state.entry.view = "";
    state.entry.activeUser = id;
    /*-----ADD ENTRY FIELDS BELOW-----*/
    state.entry.id_user = "";
    state.entry.id_project = "";
    state.entry.type = "";
    state.entry.type_other = "";
    state.entry.title = "";
    state.entry.organization = "";
    state.entry.source = "";
    state.entry.date_submitted = "";
    state.entry.date_month = "";
    state.entry.date_year = "";
    state.entry.award_number = "";
    state.entry.amount_requested = "";
    state.entry.amount_awarded = "";
    state.entry.date_awarded_month = "";
    state.entry.date_awarded_month_end = "";
    state.entry.date_awarded_year = "";
    state.entry.date_awarded_year_end = "";
    state.entry.date_awarded = "";
    state.entry.flag_status = "";
    state.entry.flag_show = "";
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  SET_VIEW(state, { data }) {
    state.entry.view = data;
  },
  SET_EXPORT(state, { data }) {
    state.filters.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearList({ commit }) {
    commit("CLEAR_LIST");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/fellowships", data, {
        headers: getHeader(),
      });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/fellowships/create", data, {
        headers: getHeader(),
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/fellowships/${id}`, {
        headers: getHeader(),
      });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(
        `/api/fellowships/update/${data.id}`,
        data,
        { headers: getHeader() }
      );
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/fellowships/delete", data, {
        headers: getHeader(),
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/fellowships/${id}`, {
        headers: getHeader(),
      });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post("/api/fellowships", data, {
        headers: getHeader(),
      });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }, id) {
    commit("RESET_ENTRY", { id });
  },
  setView: ({ commit }, data) => {
    commit("SET_VIEW", { data });
  },
  setExport: ({ commit }, data) => {
    commit("SET_EXPORT", { data });
  },
  exportFilters({ commit }, data) {
    commit("EXPORT_FILTERS", { data });
  },
};

const getters = {
  getFellowship: (state, getters) => state.fellowship,
  getFellowships: (state, getters) => state.fellowships,
  getExport: (state, getters) => state.export,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
