import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const state = {
  project: {},
  projects: [],
  projectsValueList: [],
  researcherValueList: [],
  researchers: [],
  keyOutcomes: {},
  export: [],
  trainees: [],
  entry: {
    show: false,
    valid: "",
    mode: "",
    id: "",
    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_cohort: "",
    jurisdictions: [],
    title: "",
    short_name: "",
    managing_po: "",
    award_id: "",
    code: "",
    date_start: "",
    date_end: "",
    date_start_baseline: "",
    date_end_baseline: "",
    flag_nce: "",
    flag_status: "",
    date1: "",
    date2: "",
    date3: "",
    date4: "",
  },
  entryInstitutions: {
    show: false,
    id: "",
    institutions: [],
    departments: [],
  },
  filters: {
    page: "",
    sectionStored: "",
    /*-----ADD FILTER FIELDS BELOW-----*/
    id_user: "",
    id_cohort: "",
    code: "",
    title: "",
    managing_po: "",
    flag_nce: "",
    flag_status: "",
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.project = {};
    state.projects = [];
    state.projectsValueList = [];
    state.researchers = [];
    state.trainees = [];
    state.filters = {
      page: "",
      sectionStored: "",
      id_user: "",
      id_cohort: "",
      code: "",
      title: "",
      managing_po: "",
      flag_nce: "",
      flag_status: "",
    };
  },
  CLEAR_PROJECTS(state) {
    state.projects = [];
  },
  FETCH(state, { list }) {
    state.projects = list;
  },
  FETCH_KEY_OUTCOMES(state, { data }) {
    state.keyOutcomes = data;
  },
  FETCH_RESEARCHERS(state, { list }) {
    state.researchers = list;
  },
  FETCH_TRAINEES(state, { list }) {
    state.trainees = list;
  },
  CREATE(state, { data }) {
    state.projects.data.push(data);
  },
  READ(state, { data }) {
    state.project = data;
  },
  UPDATE(state, { data }) {
    const idx = state.projects.data.map((item) => item.id).indexOf(data.id);
    state.projects.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.projects.data.map((item) => item.id).indexOf(id);
    state.projects.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    state.entry.id_cohort = data.id_cohort;
    if (data.jurisdictions) {
      state.entry.jurisdictions = [];
      for (var i = 0; i < data.jurisdictions.length; i++) {
        state.entry.jurisdictions.push(data.jurisdictions[i].id);
      }
    } else {
      state.entry.jurisdictions = [];
    }
    state.entry.title = data.title;
    state.entry.short_name = data.short_name;
    state.entry.managing_po = data.managing_po;
    state.entry.award_id = data.award_id;
    state.entry.code = data.code;
    state.entry.date1 = data.date_start;
    state.entry.date2 = data.date_end;
    state.entry.date3 = data.date_start_baseline;
    state.entry.date4 = data.date_end_baseline;
    state.entry.flag_nce = data.flag_nce;
    state.entry.flag_status = data.flag_status;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = true;
    state.entry.id = "";
    state.entry.id_cohort = "";
    state.entry.jurisdictions = [];
    state.entry.title = "";
    state.entry.short_name = "";
    state.entry.managing_po = "";
    state.entry.award_id = "";
    state.entry.code = "";
    state.entry.date_start = "";
    state.entry.date_end = "";
    state.entry.date_start_baseline = "";
    state.entry.date_end_baseline = "";
    state.entry.date1 = "";
    state.entry.date2 = "";
    state.entry.date3 = "";
    state.entry.date4 = "";
    state.entry.flag_nce = "";
    state.entry.flag_status = 1;
  },
  ENTRY_INSTITUTIONS(state, { data }) {
    state.entryInstitutions.id = data.id;
    var i;
    for (i = 0; i < data.institutions.length; i++) {
      state.entryInstitutions.institutions.push(data.institutions[i].id);
    }
    var ii;
    for (ii = 0; ii < data.departments.length; ii++) {
      state.entryInstitutions.departments.push(data.departments[ii].id);
    }
    state.entryInstitutions.show = true;
  },
  VALUE_LIST(state, { list }) {
    state.projectsValueList = list;
  },
  RESEARCHER_VALUE_LIST(state, { list }) {
    state.researcherValueList = list;
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  SET_ACTIVE_PROJECT(state, data) {
    state.activeProject = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateEntryInstitutionsField(state, field) {
    updateField(state.entryInstitutions, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearProjects: ({ commit }) => {
    commit("CLEAR_PROJECTS");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/projects", data, {
        headers: getHeader(),
      });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async fetchKeyOutcomes({ commit }, data) {
    try {
      const response = await axios.post("/api/project/keyoutcomes", data, {
        headers: getHeader(),
      });
      commit("FETCH_KEY_OUTCOMES", { data: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/projects/create", data, {
        headers: getHeader(),
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/projects/${id}`, {
        headers: getHeader(),
      });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(
        `/api/projects/update/${data.id}`,
        data,
        { headers: getHeader() }
      );
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async updateInstitutions({ commit }, data) {
    try {
      const response = await axios.put(
        `/api/projects/updateinstitutions/${data.id}`,
        data,
        { headers: getHeader() }
      );
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/projects/delete", data, {
        headers: getHeader(),
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/projects/${id}`, {
        headers: getHeader(),
      });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async entryInstitutions({ commit }, id) {
    try {
      const response = await axios.get(`/api/projects/${id}`, {
        headers: getHeader(),
      });
      commit("ENTRY_INSTITUTIONS", { data: response.data });
    } catch (error) {}
  },
  async valueList({ commit }) {
    try {
      const response = await axios.get("/api/projects/valuelist/1", {
        headers: getHeader(),
      });
      commit("VALUE_LIST", { list: response.data });
    } catch (error) {}
  },
  async researcherValueList({ commit }, data) {
    try {
      const response = await axios.post(
        "/api/projects/researchervaluelist",
        data,
        { headers: getHeader() }
      );
      commit("RESEARCHER_VALUE_LIST", { list: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  },
  async editParticipation({ commit }, data) {
    try {
      const response = await axios.post("api/project/participant/edit", data, {
        headers: getHeader(),
      });
      commit("READ", response.data);
    } catch (error) {}
  },
  async addParticipation({ commit }, data) {
    try {
      const response = await axios.post("api/project/participant/add", data, {
        headers: getHeader(),
      });
      commit("READ", response.data);
    } catch (error) {}
  },
  async researchers({ commit }, data) {
    try {
      const response = await axios.post("api/project/researchers", data, {
        headers: getHeader(),
      });
      commit("FETCH_RESEARCHERS", { list: response.data });
    } catch (error) {}
  },
  async trainees({ commit }, data) {
    try {
      const response = await axios.post("api/project/trainees", data, {
        headers: getHeader(),
      });
      commit("FETCH_TRAINEES", { list: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post("/api/project/keyoutcomes", data, {
        headers: getHeader(),
      });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
};

const getters = {
  getProject: (state, getters) => state.project,
  getProjects: (state, getters) => state.projects,
  getFilters: (state, getters) => state.filters,
  getExport: (state, getters) => state.export,
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntryInstitutionsField(state) {
    return getField(state.entryInstitutions);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getEntryInstitutions: (state, getters) => state.entryInstitutions,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
