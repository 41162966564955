import { getField, updateField } from "vuex-map-fields";
import { getHeader } from "@/env";

const state = {
  dissertations: [],
  dissertation: {},
  export: [],
  entry: {
    show: false,
    valid: "",
    mode: "",
    view: "",
    id: "",

    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_user: "",
    id_project: "",
    title: "",
    authors: "",
    institution: "",
    month: "",
    year: "",
    description: "",
    ack_federal_support: "",
    ack_nsf_epscor_support: "",
    type: "",
  },
  filters: {
    page: "",
    view: "",
    export: "",
    id_user: "",
    id_project: "",
    /*-----ADD FILTER FIELDS BELOW-----*/
    title: "",
    type: "",
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.dissertations = [];
    state.dissertation = {};
    state.entry = {
      show: false,
      valid: "",
      mode: "",
      view: "",
      id: "",

      /*-----ADD ENTRY FIELDS BELOW-----*/
      id_user: "",
      id_project: "",
      title: "",
      authors: "",
      institution: "",
      month: "",
      year: "",
      description: "",
      ack_federal_support: "",
      ack_nsf_epscor_support: "",
      type: "",
    };
    state.filters = {
      page: "",
      view: "",
      export: "",
      id_user: "",
      id_project: "",
      /*-----ADD FILTER FIELDS BELOW-----*/
      title: "",
      type: "",
    };
  },
  CLEAR_LIST(state) {
    state.dissertations = [];
  },
  FETCH(state, { list }) {
    state.dissertations = list;
  },
  EXPORT_FILTERS(state, { data }) {
    state.filters.id_project = data.id_project;
    state.filters.export = data.export;
    state.filters.view = 2;
    state.filters.title = "";
    state.filters.type = "";
    state.filters.institution = "";
    state.filters.flag_status = "";
  },
  CREATE(state, { data }) {
    state.dissertations.data.unshift(data);
    if (state.entry.view === 1)
      state.dissertations.data = state.dissertations.data.slice(0, 4);

    if (data) {
      let currentTotal = state.dissertations.total;
      state.dissertations.total = currentTotal + 1;
    }
  },
  READ(state, { data }) {
    state.dissertation = data;
  },
  UPDATE(state, { data }) {
    const idx = state.dissertations.data
      .map((item) => item.id)
      .indexOf(data.id);
    state.dissertations.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.dissertations.data.map((item) => item.id).indexOf(id);
    state.dissertations.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.dissertation = data;
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id;
    /*-----ADD ENTRY FIELDS BELOW-----*/
    state.entry.id_user = data.id_user;
    state.entry.id_project = data.id_project;
    state.entry.title = data.title;
    state.entry.authors = data.authors;
    state.entry.institution = data.institution;
    state.entry.month = data.month;
    state.entry.year = data.year;
    state.entry.description = data.description;
    state.entry.ack_federal_support = data.ack_federal_support;
    state.entry.ack_nsf_epscor_support = data.ack_nsf_epscor_support;
    state.entry.type = data.type;

    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.mode = 1;
    state.entry.valid = true;
    state.entry.id = "";
    state.entry.view = "";
    /*-----ADD ENTRY FIELDS BELOW-----*/
    state.entry.id_user = "";
    state.entry.id_project = "";
    state.entry.title = "";
    state.entry.authors = "";
    state.entry.institution = "";
    state.entry.month = "";
    state.entry.year = "";
    state.entry.description = "";
    state.entry.ack_federal_support = "";
    state.entry.ack_nsf_epscor_support = "";
    state.entry.type = "";
  },
  EXPORT_EXCEL(state, { data }) {
    state.export = data;
  },
  SET_VIEW(state, { data }) {
    state.entry.view = data;
  },
  SET_EXPORT(state, { data }) {
    state.filters.export = data;
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit("CLEAR_STORE");
  },
  clearList({ commit }) {
    commit("CLEAR_LIST");
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post("/api/dissertations", data, {
        headers: getHeader(),
      });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/dissertations/create", data, {
        headers: getHeader(),
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/dissertations/${id}`, {
        headers: getHeader(),
      });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(
        `/api/dissertations/update/${data.id}`,
        data,
        { headers: getHeader() }
      );
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/dissertations/delete", data, {
        headers: getHeader(),
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/dissertations/${id}`, {
        headers: getHeader(),
      });
      commit("ENTRY", { data: response.data });
    } catch (error) {}
  },
  async exportExcel({ commit }, data) {
    try {
      const response = await axios.post("/api/dissertations", data, {
        headers: getHeader(),
      });
      commit("EXPORT_EXCEL", { data: response.data });
    } catch (error) {}
  },
  resetEntry({ commit }) {
    commit("RESET_ENTRY");
  },
  setView: ({ commit }, data) => {
    commit("SET_VIEW", { data });
  },
  setExport: ({ commit }, data) => {
    commit("SET_EXPORT", { data });
  },
  exportFilters({ commit }, data) {
    commit("EXPORT_FILTERS", { data });
  },
};

const getters = {
  getDissertation: (state, getters) => state.dissertation,
  getDissertations: (state, getters) => state.dissertations,
  getExport: (state, getters) => state.export,
  getFilters: (state, getters) => state.filters,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
