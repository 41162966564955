import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  emails: [],
  entry: {
    show: '',
    valid: false,
    mode: '',
    section: '',

    id_user: '',
    to: '',
    cc: '',
    subject: '',
    body: '',
    from: '',
  },
  entryAdmin: {
    show: '',
    valid: false,
    mode: '',
    id: '',

    date_send: '',
    time_send: '',
    subject: '',
    body: '',
    user_type: [],
    flag_mode: '',
    flag_status: '',
    flag_show: '',
    date1: '',
    projects: [],
  },
  filters: {
    date_send: '',
    flag_status: '',
    date1: '',
  }
};

const mutations = {
  CLEAR_STORE(state) {
    state.emails = [],
    state.entry = {
      show: '',
      valid: '',
      mode: '',
      section: '',
      /*-----ADD ENTRY FIELDS BELOW-----*/
      id_user: '',
      to: '',
      cc: '',
      subject: '',
      body: '',
      from: '',
    }
    state.entryAdmin = {
      show: '',
      valid: '',
      mode: '',
      id: '',

      date_send: '',
      time_send: '',
      subject: '',
      body: '',
      user_type: [],
      flag_mode: '',
      flag_status: '',
      flag_show: '',
      date1: '',
      projects: [],
    },
    state.filters = {
      page: '',
      /*-----ADD FILTER FIELDS BELOW-----*/
      date_send: '',
      flag_status: '',
      date1: ''
    }
  },
  FETCH(state, { list }) {
    state.emails = list;
  },
  CREATE(state, { data }) {
    state.emails.data.push(data);
  },
  READ(state, { data }) {
    state.email = data;
  },
  UPDATE(state, { data }) {
    const idx = state.emails.data.map(item => item.id).indexOf(data.id);
    state.emails.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.emails.data.map(item => item.id).indexOf(id);
    state.emails.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = data.mode;
    state.entry.valid = false;
    state.entry.section = data.section;

    state.entry.id_user = data.id_user;
    state.entry.to = data.to;
    state.entry.cc = data.cc;
    state.entry.subject = data.subject;
    state.entry.body = data.body;
    state.entry.from = data.from;
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.valid = false;

    state.entry.id_user = '';
    state.entry.to = '';
    state.entry.cc = '';
    state.entry.subject = '';
    state.entry.body = '';
  },
  ENTRY_ADMIN(state, { data }) {
    state.entryAdmin.mode = 2;
    state.entryAdmin.valid = false;

    state.entryAdmin.id = data.id;
    state.entryAdmin.date1 = data.date_send;
    state.entryAdmin.time_send = data.time_send;
    state.entryAdmin.subject = data.subject;
    state.entryAdmin.body = data.body;
    state.entryAdmin.user_type = data.user_type;
    state.entryAdmin.flag_mode = data.flag_mode;
    state.entryAdmin.flag_status = data.flag_status;
    state.entryAdmin.flag_show = data.flag_show;
    state.entryAdmin.projects = data.projects;
    state.entryAdmin.show = true;
  },
  RESET_ENTRY_ADMIN(state) {
    state.entryAdmin.valid = false;

    state.entryAdmin.id = '';
    state.entryAdmin.date1 = '';
    state.entryAdmin.time_send = '';
    state.entryAdmin.subject = '';
    state.entryAdmin.body = '';
    state.entryAdmin.user_type = [];
    state.entryAdmin.flag_status = 1;
    state.entryAdmin.flag_mode = 1;
    state.entryAdmin.flag_show = 1;
    state.entryAdmin.projects = []
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateEntryAdminField(state, field) {
    updateField(state.entryAdmin, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/emails', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/emails/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/emails/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/emails/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/emails/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entryAdmin({ commit }, id) {
    try {
      const response = await axios.get(`/api/emails/${id}`, { headers: getHeader() });
      commit('ENTRY_ADMIN', { data: response.data });
    } catch (error) {

    }
  },
  async emailSupport({ commit }, data) {
    try {
      const response = await axios.post('/api/emails/support', data, { headers: getHeader() });
      return response ;
    } catch (error) {
      return error
    }
  },
  openEmail({commit}, data) {
    commit('ENTRY', {data: data });
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
  resetEntryAdmin({commit}) {
    commit('RESET_ENTRY_ADMIN');
  },
};

const getters = {
  getEntryField(state) {
    return getField(state.entry);
  },
  getEntryAdminField(state) {
    return getField(state.entryAdmin);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getEntryAdmin: (state, getters) => state.entryAdmin,
  getFilters: (state, getters) => state.filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
