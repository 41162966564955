import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-pro/css/all.css';

import Vue from 'vue'
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'

import { siteURL} from '@/env';

window.axios = axios;

const opts = {
  icons: {
    iconfont: 'fa',
    values: {
      arcreactor: ''
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#005799',
        secondary: '#225069',
        accent: '#F5F5F5',
        error: '#EF5350',
        success: '#81C784',
        nsfgrey: '#46484E'
      },
      dark: {
        primary: '#40C4FF',
        secondary: '#FFFFFF',
        accent: '#424242',
        error: '#EF5350',
        success: '#81C784',
      },
    },
  },
};
Vue.use(Vuetify);

Vue.config.productionTip = false

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = siteURL;

new Vue({
  router,
  store,
  vuetify: new Vuetify(opts),
  render: h => h(App)
}).$mount('#app')
