import { getField, updateField } from 'vuex-map-fields';
import { getHeader } from '@/env';

const state = {
  participation: [],
  record: {},
  entry: {
    show: false,
    valid: '',
    mode: '',
    id: '',
    /*-----ADD ENTRY FIELDS BELOW-----*/
    id_project: '',
    date_start_project: '',
    id_user: '',
    email: '',
    name_first: '',
    name_last: '',
    password: '',
    passwordConfirmation: '',
    date_start_month: '',
    date_start_year: '',
    date_end_month: '',
    date_end_year: '',
    classification: '',
    classification_other: '',
    flag_contact_pi: '',
    flag_user_type: '',
    flag_status: '',
    emailCheck: '',
    advisors: [],
    sendInvite: '',
    flag_email: '',
    date_profile_check: '',
  },
  filters: {
    page: '',
    sectionStored: '',
    /*-----ADD FILTER FIELDS BELOW-----*/
    id_user: '',
    id_project: '',
    code: '',
    flag_user_type: '',
    flag_status: '',
  },
};

const mutations = {
  CLEAR_STORE(state) {
    state.participation = [];
    state.record = {},
    state.entry = {
      show: false,
      valid: '',
      mode: '',
      id: '',
      /*-----ADD ENTRY FIELDS BELOW-----*/
      id_project: '',
      date_start_project: '',
      id_user: '',
      email: '',
      name_first: '',
      name_last: '',
      password: '',
      passwordConfirmation: '',
      date_start_month: '',
      date_start_year: '',
      date_end_month: '',
      date_end_year: '',
      classification: '',
      classification_other: '',
      flag_contact_pi: '',
      flag_user_type: '',
      flag_status: '',
      emailCheck: '',
      advisors: [],
      sendInvite: '',
      flag_email: '',
      date_profile_check: '',
    },
    state.filters = {
      page: '',
      sectionStored: '',

      id_user: '',
      id_project: '',
      code: '',
      flag_user_type: '',
      flag_status: ''
    };
  },
  CLEAR_PARTICIPATION(state) {
    state.participation = [];
  },
  FETCH(state, { list }) {
    state.participation = list;
  },
  CREATE(state, { data }) {
    state.participation.data.push(data);
  },
  READ(state, { data }) {
    state.record = data;
  },
  UPDATE(state, { data }) {
    const idx = state.participation.data.map(item => item.id).indexOf(data.id);
    state.participation.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.participation.data.map(item => item.id).indexOf(id);
    state.participation.data.splice(idx, 1);
  },
  ENTRY(state, { data }) {
    state.entry.mode = 2;
    state.entry.valid = true;
    state.entry.id = data.id ? data.id : '';
    state.entry.id_project = state.entry.id_project ? state.entry.id_project : data.id_project
    state.entry.id_user = state.entry.id_user ? state.entry.id_user : data.id_user
    state.entry.email = data.user.email
    state.entry.name_first = data.user.name_first
    state.entry.name_last = data.user.name_last
    state.entry.password = ''
    state.entry.passwordConfirmation = ''
    state.entry.flag_contact_pi = data.flag_contact_pi
    state.entry.flag_user_type = data.flag_user_type
    state.entry.flag_status = data.flag_status
    state.entry.flag_email = data.flag_email
    state.entry.emailCheck = 1
    state.entry.advisors = data.user.advisors ? data.user.advisors : []
    state.entry.date_start_month = data.date_start_month
    state.entry.date_start_year = data.date_start_year
    state.entry.date_end_month = data.date_end_month
    state.entry.date_end_year = data.date_end_year
    state.entry.date_profile_check = data.date_profile_check
    state.entry.classification = data.classification
    state.entry.classification_other = data.classification_other
    state.entry.sendInvite = true
    state.entry.show = true;
  },
  RESET_ENTRY(state) {
    state.entry.mode = 1
    state.entry.valid = true
    state.entry.id = ''
    state.entry.date_start_project = '';
    state.entry.id_project = ''
    state.entry.id_user = ''
    state.entry.email = ''
    state.entry.name_first = ''
    state.entry.name_last = ''
    state.entry.password = ''
    state.entry.passwordConfirmation = ''
    state.entry.flag_contact_pi = ''
    state.entry.flag_user_type = ''
    state.entry.emailCheck = ''
    state.entry.advisors = []
    state.entry.date_start_month = ''
    state.entry.date_start_year = ''
    state.entry.date_end_month = ''
    state.entry.date_end_year = ''
    state.entry.sendInvite = ''
    state.entry.flag_email = '';
    state.entry.date_profile_check = ''
    state.entry.classification = ''
    state.entry.classification_other = ''
    state.entry.flag_status = 1;
  },
  FOUND_USER(state, { data }) {
    state.entry.valid = true;
    state.entry.id_user = data.id
    state.entry.email = data.email ? data.email : state.entry.email
    state.entry.name_first = data.name_first
    state.entry.name_last = data.name_last
    state.entry.emailCheck = 1
  },
  updateEntryField(state, field) {
    updateField(state.entry, field);
  },
  updateFilterField(state, field) {
    updateField(state.filters, field);
  },
  updateField,
};

const actions = {
  clearStore: ({ commit }) => {
    commit('CLEAR_STORE');
  },
  clearParticipation: ({ commit }) => {
    commit('CLEAR_PARTICIPATION');
  },
  async fetch({ commit }, data) {
    try {
      const response = await axios.post('/api/projectuserjoin', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/projectuserjoin/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/projectuserjoin/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/projectuserjoin/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async updateParticipationList({ commit }, id) {
    try {
      const response = await axios.get(`/api/projectuserjoin/${id}`, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/projectuserjoin/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async entry({ commit }, id) {
    try {
      const response = await axios.get(`/api/projectuserjoin/${id}`, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async readByEmail({ commit }, data) {
    try {
      const response = await axios.post('/api/users/search/email', data, { headers: getHeader() });
      commit('FOUND_USER', { data: response.data });
    } catch (error) {

    }
  },
  async activeProjectUser({ commit }, data) {
    try {
      const response = await axios.post('/api/projectuserjoin/active', data, { headers: getHeader() });
      commit('ENTRY', { data: response.data });
    } catch (error) {

    }
  },
  async updateYearList({ commit }, data) {
    commit('UPDATE_DATE_YEAR', { data: data });
  },
  resetEntry({commit}) {
    commit('RESET_ENTRY');
  },
};

const getters = {
  getParticipation: (state, getters) => state.participation,
  getRecord: (state, getters) => state.record,
  getFilters: (state, getters) => state.filters,
  getAdvisors: (state, getters) => state.entry.advisors,
  getEntryField(state) {
    return getField(state.entry);
  },
  getFilterField(state) {
    return getField(state.filters);
  },
  getEntry: (state, getters) => state.entry,
  getField,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
